<style>
  ::ng-deep div .iti__country-list--dropup {
    width: 389px;
  }

  ::ng-deep div .iti__country-list {
    width: 389px;
  }
</style>
<header [ngClass]="
    scrollImageSec
      ? ' fixed-top shadow desktop-header-media-qurey'
      : 'fixed-top desktop-header-media-qurey'
  " id="desktop-header">

  <div class="container-fluid header-maan">
    <div class="container">
      <div class="row">
        <div *ngIf="scrollImage && !isLogin" class="col-md-2 text-left">
          <a [routerLink]="['/']" routerLinkActive="router-link-active">
            <img class="img-responsive mw-100 h-67" id="change-image" src="../../../assets/Pacsend_logo11.webp"
              width="68" height="48" alt="logo" />
          </a>
        </div>
        <div *ngIf="scrollImageSec && !isLogin" class="col-md-2 text-left">
          <a [routerLink]="['/']" routerLinkActive="router-link-active">
            <img [src]="'../../../assets/logo resize-02.webp'" alt="Logo-Pacsend"
              class="img-responsive img-get-top-mar-sec" width="156" height="48" id="change-image" />
          </a>
        </div>

        <div *ngIf="scrollImage && isLogin" class="col-md-2 text-left">
          <a [routerLink]="['/all-ads']" routerLinkActive="router-link-active">
            <!-- main logo -->
            <img class="img-responsive mw-100 h-67" id="change-image" src="../../../assets/Pacsend_logo11.webp"
              width="68" height="48" alt="logo" />

          </a>
        </div>

        <div *ngIf="scrollImageSec && isLogin" class="col-md-2 text-left">
          <a [routerLink]="['/all-ads']" routerLinkActive="router-link-active">
            <img [src]="'../../../assets/logo resize-02.webp'" alt="Logo-Pacsend"
              class="img-responsive img-get-top-mar-sec" width="156" height="48" id="change-image" />
          </a>
        </div>

        <div *ngIf="isLogin" class="col text-center p-t-20">
          <a [routerLink]="['/map']" routerLinkActive="router-link-active" [ngClass]="
              clickMap
                ? 'p-r-10 p-l-10 font-14 active mr-51'
                : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'
            " (click)="map()">Map</a>

          <a [routerLink]="['/all-ads']" [ngClass]="
              clickAllAds
                ? 'p-r-10 p-l-10 font-14 active mr-51'
                : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'
            " (click)="allAdsActive()">All Ads</a>
          <a [routerLink]="['/my-ads']" routerLinkActive="router-link-active" [ngClass]="
              clickMyAds
                ? 'p-r-10 p-l-10 font-14 active mr-51'
                : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'
            " (click)="myAds()">My Ads</a>
          <a (click)="onClickChat()" [routerLink]="['/chat']" routerLinkActive="router-link-active" [ngClass]="
              clickChat
                ? 'p-r-10 p-l-10 font-14 active mr-51'
                : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'
            ">Chat</a>
          <a [routerLink]="['/favourites']" routerLinkActive="router-link-active" [ngClass]="
              clickFavourites
                ? 'p-r-10 p-l-10 font-14 active mr-51'
                : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'
            " (click)="favourites()">Favourites</a>

          <button (click)="openNewAd(newAd, 0)" class="btn_post_add">
            Post Ad
          </button>
          <a [routerLink]="['/my-activity']" routerLinkActive="router-link-active" [ngClass]="
              clickMyActivity
                ? 'p-r-10 p-l-10 font-14 active mr-51'
                : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'
            " (click)="onClickMyActivity()">Activity</a>
            <a [routerLink]="['/how-to-use']" routerLinkActive="router-link-active" [ngClass]="
            clickMyActivity
              ? 'p-r-10 p-l-10 font-14 active mr-51'
              : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'
          ">How to Use</a>

        </div>
        <div class="col text-right p-t-20" *ngIf="!isLogin">
          <a [routerLink]="['/']" routerLinkActive="router-link-active" class="p-r-10 p-l-10 font-17 gap-nav">
            All Ads</a>
          <a [routerLink]="['/about']" routerLinkActive="router-link-active" class="p-r-10 p-l-10 font-17 gap-nav">
            About</a>
          <a href="/how-to-use#whypacsend" class="p-r-10 p-l-10 font-17 gap-nav">Why Pacsend ?</a>
          <!-- <a (click)="onScrollbyID('whypacsend')" class="p-r-10 p-l-10 font-17 gap-nav">Why Pacsend ?</a> -->
          <a href="/how-to-use#howtouse" class="p-r-10 p-l-10 font-17 gap-nav">How to use</a>
          <!-- <a (click)="onScrollbyID('howtouse')" class="p-r-10 p-l-10 font-17 gap-nav">How to use</a> -->
          <a [routerLink]="['/login']" routerLinkActive="router-link-active" class="btn-login">LOGIN</a>
        </div>

        <div *ngIf="isLogin" class="col-md-3 text-right p-t-10" >
          <a class="cursor" (click)="getId(userId)"><label>
              <strong class="font-13 cursor" [ngClass]="clickMyProfle? 'p-r-10 p-l-10 font-14 active mr-51': 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'" >{{ username }}</strong>&nbsp;&nbsp;</label>
            <img class="profile-avatar" src="{{ avatar_image }}" />
          </a>
          <div class="Bell">

            <a [routerLink]="['/notification']" [ngClass]="clickNotification? 'p-r-10 p-l-10 font-14 active mr-51'
              : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'" routerLinkActive="router-link-active" (click)="notification()">
              <i class="far fa-bell"></i>
              &nbsp;&nbsp;&nbsp;
            </a>
            <span *ngIf="showNotification" [class]="notifictionClass"></span>
          </div>
          <a (click)="logout()" class="p-r-10 p-l-10 font-16 colorRed cursor">
            <i class="fas fa-sign-out-alt ml-2 get-color-red" aria-hidden="true"></i>
          </a>
          <div [class]="BellMenuClass">
            <ul class="top-21 position-absolute">
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <div></div>
  </div>

  <div class="w-100 red-alert" *ngIf="userVerfy">
    <div class="col-md-12 verifyAlert" *ngIf="equalzero">
      <div class="row cursor" (click)="verifyProfile(verifyProfileStepOne)">
        <div class="col-md-12 text-center">
          <span class="color-white font-14 font-500">
            <p class="m-2">Click Here to verify your account first !!!</p>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 yellow-alert" *ngIf="userVerfy">
    <div class="col-md-12 verifyAlert1" *ngIf="equaltwo">
      <div class="row cursor" (click)="verifyProfile(verifyProfileStepOne)">
        <div class="col-md-12 text-center">
          <span class="color-blackk font-14 font-500">
            <p class="m-2">
              Your account verification is now under process.
              <b>Verification request received.</b>
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</header>

<header class="container-fluid table-show header-mobile-maan header-box-shadow">

  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col text-left toggle-mobile">
        <nav role="navigation">
          <div id="menuToggle">
            <a (click)="openNav()">
              <i class="fa fa-bars colorRed"></i>
            </a>
            <div id="menu" *ngIf="!isLogin && navMenu" class="changed-menu-show">
              <div class="TopSection d-flex justify-content-between align-items-center">                
                <a [routerLink]="['/']" routerLinkActive="router-link-active" class="MobLogo"><img [src]="'../../../assets/logo resize-02.webp'" alt="image" /></a>
                <a (click)="openNav(1)" class="CloseMobMenu"><i class="fas fa-times"></i></a>
              </div>
              <div class="MenuBox">
                <a [routerLink]="['/']" routerLinkActive="router-link-active" title="About" (click)="openNav(1)">
                  <i class="fas fa-address-card"></i>
                  All Ads
              </a>
                <a [routerLink]="['/about']" routerLinkActive="router-link-active" title="About" (click)="openNav(1)">
                    <i class="fas fa-address-card"></i>
                    About
                </a>
                <a title="Why Pacsend ?" href="/how-to-use#whypacsend">
                    <i class="fas fa-question-circle"></i>
                    Why Pacsend
                </a>                
                <!-- <a title="Why Pacsend ?" (click)="onScrollbyID('whypacsend'); openNav(1)">
                    <i class="fas fa-question-circle"></i>
                    Why Pacsend
                </a> -->
                <!-- <a title="How To Use" (click)="onScrollbyID('howtouse');openNav(1)">
                    <i class="fas fa-user"></i>
                    How To Use
                </a> -->
                <a title="How To Use" href="/how-to-use#howtouse">
                  <i class="fas fa-user"></i>
                  How To Use
              </a>
              </div>                            
            </div>
            <div *ngIf="isLogin && navMenu" id="menu" class="changed-menu-show">
              <div class="TopSection d-flex justify-content-between align-items-center">                
                <a [routerLink]="['/']" routerLinkActive="router-link-active" class="MobLogo"><img [src]="'../../../assets/logo resize-02.webp'" alt="image" /></a>
                <a (click)="openNav(1)" class="CloseMobMenu"><i class="fas fa-times"></i></a>
              </div>
              <div class="MenuBox">
                <a [routerLink]="['/map']" routerLinkActive="router-link-active" (click)="[map(), openNav(0)]">
                    <i class="fas fa-map-marker-alt"></i>
                    Map
                </a>
                <a (click)="openNav(1)" [routerLink]="['/all-ads']" routerLinkActive="router-link-active" title="All Ads">
                    <i class="fas fa-sort-alpha-down"></i>
                    All Ads
                </a>
                <a [routerLink]="['/my-ads']" routerLinkActive="router-link-active" title="My Ads" (click)="openNav(1)">              
                    <i class="fas fa-sort-amount-down"></i>
                    My Ads
                </a>
                <a (click)="onClickChat(1)" [routerLink]="['/chat']" title="Chat">
                  <i class="fas fa-comments"></i>
                    Chat
                </a>
                <a (click)="openNav(1)" [routerLink]="['/favourites']" routerLinkActive="router-link-active">
                    <i class="fas fa-heart"></i>
                    Favourites
                </a>
                <button type="button" (click)="openNewAd(newAd, 1)" [disabled]="isDisabled" class="ui-arngmnt">
                    <i class="fas fa-paper-plane"></i>
                    Post Ad
                </button>
                <a [routerLink]="['/my-activity']" routerLinkActive="router-link-active" title="My Activity" (click)="openNav(1)">              
                  <i class="fas fa-sort-amount-down"></i>
                  Activity
              </a>
              <a [routerLink]="['/how-to-use']" routerLinkActive="router-link-active" title="My Activity" (click)="openNav(1)">              
                <i class="fas fa-sort-amount-down"></i>
                How to Use
            </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="col text-center">
        <a [routerLink]="['/']" routerLinkActive="router-link-active">
          <img [src]="'../../../assets/logo resize-02.webp'" class="mob-v-center-logo mt-3 mb-4" style="height: 32px;" alt="image" />
        </a>
      </div>
      <div class="col d-flex align-items-center justify-content-end location-mobile MobIconLinks">
        <a *ngIf="isLogin" [routerLink]="['/notification']" [ngClass]="clickNotification? 'p-r-10 p-l-10 font-14 active mr-51'
        : 'p-r-10 p-l-10 font-14 mr-51 font-700 color-figma-gray'" routerLinkActive="router-link-active">
          <i class="far fa-bell mob-fa-bell"></i>
          <span *ngIf="showNotification" [class]="notifictionClass"></span>
        </a>
        <a *ngIf="!isLogin" [routerLink]="['/login']" routerLinkActive="router-link-active">
          <i class="fas fa-user-circle"></i>
        </a>
        <a (click)="viewprofile()" *ngIf="isLogin">
          <i class="fas fa-user-circle"></i>
        </a>
        <a (click)="logout()" *ngIf="isLogin">
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </div>
    </div>
  </div>
</header>
<div class="w-100 red-alert" *ngIf="userVerfy && isLogin">
  <div class="col-md-12 verifyAlert" *ngIf="userVerfy.is_verified == '0'">
    <div class="row cursor" (click)="verifyProfile(verifyProfileStepOne)">
      <div class="col-md-12">
        <span class="font-14 font-500 color-white">
          <p class="mt-2 text-center">Click Here to verify your account first !!!</p>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="w-100 yellow-alert" *ngIf="userVerfy">
  <div class="col-md-12 verifyAlert1" *ngIf="userVerfy.is_verified == '2'">
    <div class="row cursor" (click)="verifyProfile(verifyProfileStepOne)">
      <div class="col-md-12">
        <span class="font-14 font-500 color-blackk">
          <p class="mt-2 text-center">Your account verification is now under process!!</p>
        </span>
      </div>

    </div>
  </div>
</div>
<!-- =============================================================================================================================== -->

<ng-template #newAd let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title text-center w-100 font-21 pt-0 pb-3 font-400 headd" id="modal-basic-title btn-ad-text-responsive">
        New Ad
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); closeFirstModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-l-r-10px p-t-b-10">
      <div class="row m-b-20">
        <!-- Sender Box -->
        <div class="col-12 col-xs-12 col-sm-12 col-md-6">
          <input type="hidden" />
          <button (click)="onSelectNewAdType(1, packageInfo, whereAreYouTravellingFrom)"
            style="height: 160px !important" class="line-height-120 width-100 btn-ad-type btn-ad-responsive">
            Send
          </button>
        </div>
        <!-- carier bOX -->
        <div class="col-12 col-xs-12 col-sm-12 col-md-6">
          <input type="hidden" />
          <button (click)="onSelectNewAdType(2, packageInfo, whereAreYouTravellingFrom)"
            style="height: 160px !important" class="line-height-120 width-100 btn-ad-type btn-ad-responsive">Carry
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Package Info -->
<ng-template #packageInfo let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 20%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-500 headd" id="modal-basic-title">
        Package Info
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); reset()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body pt-0 pb-0 p-l-r-10px">
      <div class="container addImg">
        <div class="row image-row" *ngIf="!showImages.length">
          <div style="cursor: pointer" (click)="croperModal(CropperModal)" class="col image-col bgcolor-4">
            <span *ngIf="images.length <= 0" style="cursor: pointer" (click)="croperModal(CropperModal)"><svg
                style="margin-bottom: 20% !important" width="50" height="40" viewBox="0 0 50 40" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M45 0H5C2.2425 0 0 2.2425 0 5V35C0 37.7575 2.2425 40 5 40H45C47.7575 40 50 37.7575 50 35V5C50 2.2425 47.7575 0 45 0ZM11.25 7.5C12.2446 7.5 13.1984 7.89509 13.9016 8.59835C14.6049 9.30161 15 10.2554 15 11.25C15 12.2446 14.6049 13.1984 13.9016 13.9017C13.1984 14.6049 12.2446 15 11.25 15C10.2554 15 9.30161 14.6049 8.59835 13.9017C7.89509 13.1984 7.5 12.2446 7.5 11.25C7.5 10.2554 7.89509 9.30161 8.59835 8.59835C9.30161 7.89509 10.2554 7.5 11.25 7.5ZM25 32.5H7.5L17.5 20L21.25 25L28.75 15L42.5 32.5H25Z"
                  fill="#AEBECD" />
              </svg>

              <br />
              <span class="color-figma font-500 font-weight-bold">Add image</span></span>
            <div class="text-center" *ngIf="showImages.length > 0">
              <div (click)="showImages = []; images = []">
                <i class="fa fa-times-circle cros-image"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row image-row" *ngIf="showImages.length">
          <div class="col image-col bgcolor-4">
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item" style="border-bottom: none;padding-bottom: 0px;"
                  *ngFor="let image of showImages; let i = index"
                  [ngClass]="{ 'carousel-item': true, active: i === imageIndex }">
                  <img [src]="image" (click)="croperModal(CropperModal)" style="
                  border-radius: 20px;
                  cursor: pointer;
                  width: 100%;
                  height: 196px;" class="" />
                  <a class="carousel-control-prev" role="button" (click)="previousSlide(i)" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" (click)="nextSlide(i)" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="form-group m-t-20">
        <input style="border: solid 2px #aebecd; border-radius: 4px; color: black" #title class="form-control"
          autocomplete="off" name="title" [(ngModel)]="senderAd.title" type="text" placeholder="Title (eg. Book)" />
      </div>
      <div class="catergories-div">
        <div class="form-group frm-grp ngSelect">
          <ng-multiselect-dropdown class="font-14" 
          [placeholder]="'Select Categories'" 
          [data]="categories"
          [(ngModel)]="selectedTag"
          (onSelect)="selectCategory($event)" 
          (onDeSelect)="deSelectCategory($event)"
          [settings]="categoryDropdownSettings">
        </ng-multiselect-dropdown>
          <!-- <ng-select 
            name="categoryies" 
            [items]="categories" 
            bindLabel="title" 
            bindValue="id" 
            [multiple]="true"
            [addTag]="addCustomeCategory"
            placeholder="Select Categories" 
            [(ngModel)]="senderAd.categoryIds">
          </ng-select>      -->
        </div>
        <div class="custome-category-btn">
          <button 
          type="button" 
          class="btn btn-primary custome-cat-btn" 
          data-toggle="tooltip" 
          data-placement="top" 
          title="Add custome"
          (click)="openCustomeCategoryField('sender')"
          >
            <i class="bi bi-plus-circle"></i>
          </button>
        </div>
      </div>
    
      <div class="custom-cat-div">
        <div class="form-group custom-cat-field" *ngIf="senderCutomeCategory">
          <input type="text" class="form-control" (keyup.enter)="addCustomeCategory()" placeholder="Add Custom Categories" [(ngModel)]="customeCategory"
             style="color: black; border: 1px solid #aebecd; border-radius: 4px">
        </div>
        <div *ngIf="senderCutomeCategory">
          <button 
          type="button" 
          class="btn btn-primary custome-cat-btn" 
          data-toggle="tooltip" 
          data-placement="top" 
          title="save"
          >
          <i class="bi bi-check2"  (click)="addCustomeCategory()" *ngIf="customeCategory !==''"></i>
          <i class="bi bi-x" (click)="closeCustomeCategoryField()" *ngIf="customeCategory ==''"></i>
          </button>
        </div>
      </div>
    

      <div class="form-group">
        <input #weightkg style="border: solid 2px #aebecd; border-radius: 4px; color: black" class="form-control"
          type="number" step="0.01" pattern="^\d+(?:\.\d{1,2})?$" name="weight" [(ngModel)]="senderAd.weight"
          placeholder="Weight (in KG)" min="0" />
      </div>
    </div>

    <div class="modal-footer text-center pb-2 pl-3">
      <div class="row w-100">
        <div class="col-12 w-100">
          <button type="button"
            class="btn btn-primary font-bold width-100 m-t-10 m-b-10 h-56 pakage-info-next-btn-responsive"
            (click)="openSizeOfPackage(sizeOfPackage)">
            Next
          </button>
        </div>
        <div class="col-12 p-2">
          <a (click)="openPreviousModal(newAd)">Go Back</a>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<!-- Size of Package  -->
<ng-template #sizeOfPackage let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 30%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 mob-pakage-heading headd" id="modal-basic-title">
        What is the size of your package?
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 p-l-r-10px">
      <div class="">
        <div class="col-12 m-b-10 cursor" *ngFor="let travellingType of travellingTypes; let i = index"
          (click)="travelTypeSelect(i); getTravellingType(travellingType.id)"
          style="padding: 8px 0px; border-radius: 5px !important" [ngStyle]="{
            border: travelType[i] ? '2px solid #da2926' : '2px solid #E1E7EC'
          }">
          <div class="row">
            <div class="col-3 text-center col-md-2 p-r-0 p-l-21">

              <ngx-shimmer-loading *ngIf="aboutPacsendLCP" [shape]="'rect'" [width]="'56px'" [height]="'56px'">
              </ngx-shimmer-loading>

              <img *ngIf="!aboutPacsendLCP" src="https://pacsend.tech/public/uploads/traveling/{{
                  travellingType.image
                }}" class="width-100 " />
            </div>
            <div class="col-9 col-md-10 text-left">
              <a style="color: #202833 !important" class="mb-0 font-16 font-700 mob-font-txt-color">{{
                travellingType.title }}</a>
              <p style="
                  font-size: 14px;
                  font-weight: 500 !important;
                  line-height: 23.5px;
                  color: #929fb1 !important;
                " class="mb-0 font-14 font-500">
                <!-- Books, Laptops, clothes etc -->
                <!-- {{
                travellingType.description ? travellingType.description : "Books, Laptops, clothes etc" }}, -->
                {{travellingType.description}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer text-center pb-2 pl-3">
      <div class="row w-100">
        <div class="col-12 w-100">
          <button class="btn btn-primary font-bold width-100 m-t-10 m-b-10 h-56 pakage-info-next-btn-responsive"
            (click)="openPackageDestination(packageDestination)">
            Next
          </button>
        </div>
        <div class="col-12 mb-1 p-2">
          <a (click)="modal.close('Save click'); openPreviousModal(packageInfo)" class="mb-1">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #packageDestination let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 40%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 headd" id="modal-basic-title">
        Pickup Location
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 p-l-r-10px">
      <div class="form-group has-search mb-0">
        <span style="color: #929fb1 !important"
          class="fas fa-map-marker-alt form-control-feedback font-12 location-logo"></span>
        <input style="border: 2px solid #aebecd;border-radius: 4px;" (keyup.enter)="onEnter('sender', false)"
          placeholder="Enter Location" name="searchmap" [(ngModel)]="senderAd.fromLocation" id="searchmap" #searchmap
          (input)="findAdress(searchmap)" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
          class="form-control mb-1" />
      </div>
      <div id="map1" [hidden]="true">
        <agm-map> </agm-map>
      </div>
      <div>
        <agm-map [latitude]="latitude1" [longitude]="longitude1" [zoom]="zoom_adsender_from">
          <agm-marker [latitude]="latitude1" [longitude]="longitude1" (dragEnd)="markerDragEndSender($event)"
            [markerDraggable]="true">
          </agm-marker>
        </agm-map>
      </div>
    </div>
    <div class="modal-footer text-center pb-2 pl-0">
      <div class="row w-100">
        <div class="col-12 pl-0 pr-0">
          <button type="button"
            class="btn btn-primary width-90 font-bold ml-3 m-t-20 h-56 pakage-destination-btn-responsive"
            (click)="openreveiverlocation(receiverlocation)">
            Next
          </button>
        </div>
        <div class="col-12 ml-2 mt-2 p-2">
          <a (click)="openPreviousModal(sizeOfPackage)">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #receiverlocation let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 50%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 headd" id="modal-basic-title">
        Receiver Location
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 p-l-r-10px">
      <div class="form-group has-search">
        <span style="color: #929fb1 !important"
          class="fas fa-map-marker-alt form-control-feedback font-12 location-logo"></span>
        <input style="border: 2px solid #aebecd;border-radius: 4px;" id="searchmaps"
          (keyup.enter)="onEnter('sender', true)" #searchmaps [(ngModel)]="senderAd.location"
          (input)="findRecAdress(searchmaps)" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
          class="form-control" placeholder="Enter Location" />
      </div>
      <div id="map" [hidden]="true">
        <agm-map> </agm-map>
      </div>
      <div>
        <agm-map [latitude]="latitude2" [longitude]="longitude2" [zoom]="zoom_adsener_to">
          <agm-marker [latitude]="latitude2" [longitude]="longitude2" (dragEnd)="markerDragEndToSender($event)"
            [markerDraggable]="true">
          </agm-marker>
        </agm-map>
      </div>
      <!-- Display geolocation data -->
    </div>
    <div class="modal-footer text-center pb-2 p-0">
      <div class="row m-0" style="width: 100%; padding-left: 0">
        <div class="col-12 m-0 p-0">
          <button style="width: 80%" type="button"
            class="btn btn-primary font-bold m-t-20 h-56 recive-location-btn-responsive"
            (click)="opendeliveryPriority(deliveryPriority)">
            Next</button><br />
        </div>
        <div class="col-12 mt-2 p-2">
          <a (click)="openPreviousModal(packageDestination)">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Delivery Priority -->
<ng-template #deliveryPriority let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 60%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 headd" id="modal-basic-title">
        Delivery Priority
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 p-l-r-10px">
      <div class="">
        <div class="col-12 m-b-10 delivery_location cursor" *ngFor="let priority of deliverypriority; let i = index" style="
            padding: 12px 0px;
            border: 1px solid #d9d6d6;
            border-radius: 5px;
          " [ngStyle]="{
            border: deliveryPty[i] ? '2px solid #da2926' : '2px solid #E1E7EC'
          }" >
          <div *ngIf="priority.slug !==''" class="row" (click)="getPriorityid(priority.id); onClickPriority(i)">
            <div class="col-6 text-left col-md-6">
              <a style="color: #202833 !important" class="font-16 font-700 mb-0 p-l-r-10">{{ priority.title }}</a>
            </div>
            <div class="col-6 col-md-6 text-right">
              <p class="font-14 font-500 mb-0 p-l-r-10 color-new-figma">
                {{ priority.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 m-b-10" (click)="onSelectCustame_DT()" [ngStyle]="{
            border: customDT ? '2px solid #da2926' : '2px solid #E1E7EC'
          }" style="padding: 12px 0px; border-radius: 5px">
          <div class="row" style=" cursor: pointer !important;">
            <div class="col-12 text-left">
              <h5 style="color: #202833 !important" class="mb-0 p-l-r-10 font-16 font-700">
                Custom
              </h5>
            </div>
            <div class="col-12 text-left">
              <div class="form-group m-t-10 m-b-0 ml-2">
                <input style=" cursor: pointer !important;" #customdate (click)="onSelectCustame_DT()"
                  [(ngModel)]="senderAd.customDate" class="form-control" name="" [min]="todayDate" type="date"
                  placeholder="Date" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer text-center m-0 p-0">
      <div class="row" style="width: 100%; padding-right: 25px">
        <div class="col-12">
          <button style="height: 53px; border-radius: 9px" type="button"
            class="btn btn-primary font-bold width-90 m-t-10 m-b-10 go-next-mob-responive"
            (click)="openPointOfContact(pointOfContact)">
            Next
          </button>
        </div>
        <div class="col-12 go-back-mob-responive mt-2 pb-3">
          <a (click)="openPreviousModal(receiverlocation)">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Point of contact -->
<ng-template #pointOfContact let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 70%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 headd" id="modal-basic-title">
        Point Of Contact
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 p-l-r-16">
      <p class="font-14 color-new-figma">
        Enter details of the receiver to whom traveller will submit the package
        upon reaching the destination.
      </p>

      <div class="form-group m-t-20">
        <input #name class="form-control form-custom-control" [(ngModel)]="senderAd.receiverName" name="receiverName"
          type="text" placeholder="Receiver Name" />
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-12 w-100">
            <input class="form-control w-100 form-custom-control" type="text" #phone="ngModel" ng2TelInput appNumberOnly
              [(ngModel)]="senderAd.receivernumber" [ng2TelInputOptions]="{ initialCountry: countryShortName }"
              maxlength="13" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer text-center p-0">
      <div class="row m-0" style="width: 100%">
        <div style="margin-top: 40%" class="col-12 m-t-">
          <button type="button" style="width: 86%; height: 53px; border-radius: 9px"
            class="btn btn-primary font-bold m-t-10 m-b-10 point-next-mob-responive"
            (click)="openPayment(Senderpayment)">
            Next
          </button>
        </div>
        <div class="col-12 mb-2 point-go-mob-responive mt-2 pb-2">
          <a (click)="openPreviousModal(deliveryPriority)">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #payment let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 headd" id="modal-basic-title">
        Payment
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-l-r-10px">
      <p class="color-new-figma">How much you will charge per KG?</p>
      <div class="form-group">
        <input style="border: solid 2px #e1e7ec !important" #paymentCarrier class="form-control form-custom-control"
          name="MobileNumber" min="0" type="number" placeholder="Amount" />
      </div>
    </div>
    <div class="modal-footer text-center">
      <div class="row m-0 p-0" style="width: 100%; padding-right: 25px; padding-left: 0">
        <div class="col-12">
          <button type="button" style="height: 53px; border-radius: 9px"
            class="btn btn-primary font-bold width-100 m-t-10 m-b-10 point-next-mob-responive"
            (click)="openIwantToSendSuccess(sendSuccess, paymentCarrier.value)">
            Next
          </button>
        </div>
        <div style="margin-top: 40%" class="col-12 p-0">
          <a (click)="
              modal.close('Save click'); openPreviousModal(carrierPackageInfo)
            ">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Sender Payment -->
<ng-template #Senderpayment let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 85%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-500 headd" id="modal-basic-title">
        Payment
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0 pl-5 pr-5 p-l-r-10px">
      <p class="color-new-figma">How much are you willing to charge ?</p>
      <select style="width: 85px;" [(ngModel)]="senderAd.currency" class="form-control" id="currency" name="currency">
        <option *ngFor="let currency of currencys" [ngValue]="currency">{{currency}}</option>
      </select>

      <input style="width: calc(100% - 85px);top: -38px;display: block;position: relative;left: 85px;" #payment2 class="form-control input-border-grey" [(ngModel)]="senderAd.payment" name="MobileNumber"
        type="number" min="0" placeholder="Amount" />


    </div>

    <div class="modal-footer text-center p-0">
      <div class="row m-0 p-0" style="width: 100%; padding-right: 25px; padding-left: 0">
        <div class="col-12 p-2">
          <div class="term-ui">
            <div [innerHTML]="dataTerms"></div>

            <div class="tacbox">
              <div class="input-term">


                <input style=" cursor: pointer !important;" class="termsInput" id="checkbox" type="checkbox"
                  [checked]="!checkbox1" (change)="checkbox1 = !checkbox1" />
              </div>
              <label class="font-14" for="checkbox">
                Check here to indicate that you have read and agree to the
                <a class="default-color" target="_blank" href="https://pacsend.com/terms-and-condition"><b><span>Terms
                      and</span> Conditions</b>&nbsp;</a>of Pacsend</label>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <button [disabled]="checkbox1" type="button" style="height: 53px; border-radius: 9px"
            class="btn btn-primary font-bold width-90 m-t-10 m-b-10"
            (click)="openIwantToSendSuccess(sendSuccess, payment2.value)">
            Next
          </button>
        </div>
        <div class="col-12 p-2" style="margin-bottom: 12px !important">
          <a (click)="
              modal.close('Save click'); openPreviousModal(pointOfContact)
            ">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Want To Sent Successful -->
<ng-template class="mob-mr-4px" #sendSuccess let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div>
      <div class="progress md-progress">
        <div class="progress-bar" role="progressbar" style="
            width: 100%;
            background: linear-gradient(
              94deg,
              rgba(231, 218, 218, 1) 0%,
              rgba(221, 39, 33, 1) 49%,
              rgb(251 45 45) 86%
            );
          " aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="modal-header p-1">
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span class="mr-2" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center pt-0 pb-0 p-l-r-10px">
      <h3 class="modal-title w-100 mb-4 font-21">Ad Sent For Approval</h3>

      <ngx-shimmer-loading *ngIf="aboutPacsendLCP" [shape]="'circle'" [width]="'404px'" [height]="'406px'">
      </ngx-shimmer-loading>

      <img *ngIf="!aboutPacsendLCP" src="../assets/verificationSuccessful.webp" class="width-50 sucess-img-mob-v2" />
      <p class="color-new-figma">
        Your data has been posted successfully. We will notify you once your ad is approved by our quality team.
        Meantime sit back and relax.
      </p>
    </div>
    <div class="modal-footer pb-2 p-0">
      <button style="height: 53px; border-radius: 9px; margin-right: 5%" type="button"
        class="btn btn-primary font-bold width-90 m-t-10 m-b-30 mob-mb-10" (click)="RouteToAllAds()">
        Close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #whereAreYouTravellingFrom let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-500 headd" id="modal-basic-title">
        Travelling From
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); reset()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 mob-v-card-padding">
      <div class="form-group has-search mb-2">
        <span style="color: #929fb1 !important"
          class="fas fa-map-marker-alt form-control-feedback font-12 carry-icon-mob-responsive"></span>
        <input (keyup.enter)="onEnter('carry', false)" name="searchmap12" #searchmap12 id="searchmap12"
          (input)="getTravellingFrom(searchmap12)" [(ngModel)]="carriarAd.carrier_from_location" autocorrect="off"
          autocomplete="off" autocapitalize="off" spellcheck="off" type="text" class="form-control form-custom-control"
          placeholder="Enter a location" />
      </div>
      <div id="map" [hidden]="true">
        <agm-map></agm-map>
      </div>
      <div>
        <agm-map [latitude]="latitude1" [longitude]="longitude1" [zoom]="zoom_adcarry_from">
          <agm-marker [latitude]="latitude1" [longitude]="longitude1" (dragEnd)="markerDragEnd($event)"
            [markerDraggable]="true">
          </agm-marker>
        </agm-map>
      </div>
      <!-- Display geolocation data -->
    </div>
    <div class="modal-footer text-center p-0">
      <div class="row m-0" style="width: 100%">
        <div class="col-12 p-0">
          <button type="button" style="height: 53px; border-radius: 9px"
            class="btn btn-primary font-bold width-80 m-t-b-20 mob-view-btn"
            (click)="openWhereAreYouTravellngTo(whereAreYouTravellingTo)">
            Next
          </button>
        </div>
        <div class="col-12 mb-3 p-0">
          <a (click)="modal.close('Save click'); openPreviousModal(newAd)">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #whereAreYouTravellingTo let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-400 headd" id="modal-basic-title">
        Travel Destination
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 mob-v-card-padding">
      <div class="form-group has-search mb-0">
        <span style="color: #929fb1 !important"
          class="fas fa-map-marker-alt form-control-feedback font-12 carry-icon-mob-responsive"></span>
        <input id="searchmapsss" (keyup.enter)="onEnter('carry', true)" #searchmapsss
          (input)="getTravellingto(searchmapsss)" [(ngModel)]="carriarAd.carrier_to_location" autocomplete="off"
          autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control form-custom-control"
          placeholder="Enter a location" />
      </div>

      <div id="map" [hidden]="true">
        <agm-map></agm-map>
      </div>
      <div>
        <agm-map [latitude]="latitude2" [longitude]="longitude2" [zoom]="zoom_adcarry_to">
          <agm-marker [latitude]="latitude2" [longitude]="longitude2" (dragEnd)="markerDragEndTo($event)"
            [markerDraggable]="true">
          </agm-marker>
        </agm-map>
      </div>
      <!-- Display geolocation data -->
    </div>
  </div>
  <div class="modal-footer text-center p-0">
    <div class="row m-0" style="width: 100%">
      <div class="col-12 p-0">
        <button type="button" style="height: 53px; border-radius: 9px"
          class="btn btn-primary font-bold width-80 m-t-b-20 mob-view-btn"
          (click)="openTravellingFrequency(travellingFrequency)">
          Next
        </button>
      </div>
      <div class="col-12 mb-3 p-0">
        <a (click)="
            modal.close('Save click');
            openPreviousModal(whereAreYouTravellingFrom)
          ">Go Back</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #travellingFrequency let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-500 headd" id="modal-basic-title">
        Travelling Frequency
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 TF-mob-v">
      <div class="row">
        <div class="col-12 m-b-10">
          <div class="row" (click)="changeFreq(0)">
            <div [ngClass]="
                travellingFreq[0].check
                  ? travellingFreq[0].class
                  : 'col-12 text-left borderTravelling'
              ">
              <h3 style="color: #202833 !important" class="font-16 font-700">
                Daily
              </h3>
            </div>
          </div>
        </div>
        <div class="col-12 m-b-10">
          <div class="row" (click)="changeFreq(1)">
            <div [ngClass]="
                travellingFreq[1].check
                  ? travellingFreq[1].class
                  : 'col-12 text-left borderTravelling'
              ">
              <!-- [ngClass]="'hc'" -->
              <label style="color: #202833 !important" class="display-block font-16 font-700">Some Days</label>
              <div class="row" style="padding-left: 5px">
                <div #travelling *ngFor="let travellingType of DaysArray">
                  <input style="border: solid 2px #aebecd !important" #DaysArray type="checkbox" name="checkbox-option"
                    [ngClass]="changeTravellingFrequency ? 'days':'hc'" [id]="travellingType.class" [id]="travellingType.class" />
                  <label (click)="PushDays(travellingType.full_name)" [for]="travellingType.class">{{travellingType.day_name }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 m-b-10" style=" cursor: pointer !important;">
          <div class="row" (click)="changeFreq(2)">
            <div [ngClass]="
                travellingFreq[2].check
                  ? travellingFreq[2].class
                  : 'col-12 text-left borderTravelling'
              ">
              <label style="color: #202833 !important" class="display-block font-700 font-16">On A Specific Date</label>
              <input #Specificdate style="border: solid 2px #aebecd !important; cursor: pointer !important;"
                class="form-control mb-3" name="" type="date" [value]="todayDate" [min]="todayDate"
                placeholder="Daily" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer text-center p-0">
      <div class="row m-0" style="width: 100%">
        <div class="col-12 p-0">
          <button type="button" style="height: 53px; border-radius: 9px"
            class="btn btn-primary font-bold width-80 m-t-40 m-b-20 mob-view-btn" (click)="
            openWhenAreYouTravelling(carrierPackageInfo, Specificdate.value)
          ">
            Next
          </button>
        </div>
        <div class="col-12 mb-3">
          <a (click)="
              modal.close('Save click');
              openPreviousModal(whereAreYouTravellingTo)
            ">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #carrierPackageInfo let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-500 headd" id="modal-basic-title">
        Package Info
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 package-info-mob-v">
      <div class="row">
        <div class="col-12 m-b-10">
          <div class="row">
            <div class="col-12 text-left m-b-20">
            <input style="border: solid 2px #aebecd; border-radius: 4px; color: black" #title class="form-control"
            autocomplete="off" name="title" [(ngModel)]="carriarAd.title" type="text" placeholder="Title (eg. Book)" />
          </div>
          </div>
          <div class="row">
            <div class="col-12 text-left m-b-20">
              <p class="color-new-figma">Just like we have for the Payment</p>
              <input #categorykg type="number" style="border: solid 2px #aebecd !important" class="form-control" name=""
                placeholder="Capacity (in Kg)" min="0" />
            </div>
          </div>
          
 

          <div class="row">
            <div class="col-12 text-left m-b-10">
              <p class="color-new-figma mt-4">
                I can carry following types of Packages
              </p>
            </div>
            <div class="col-9 text-left">
              <!-- <ng-select name="categoryies"
                 [items]="categories" bindLabel="title" bindValue="id" 
              [multiple]="true"
              [addTag]="addCustomeCategory"
              placeholder="Select Categories" [(ngModel)]="carriarAd.categoryIds">
            </ng-select>   -->
            <ng-multiselect-dropdown class="font-14" 
            [placeholder]="'Select Categories'" 
            [data]="categories"
            [(ngModel)]="selectedTag"
            (onSelect)="selectCategory($event)" 
            (onDeSelect)="deSelectCategory($event)"
            [settings]="categoryDropdownSettings">
          </ng-multiselect-dropdown>
            </div>
            <div class="col-3 text-right mb-2">
              <button type="button" class="btn btn-primary custome-cat-btn" data-toggle="tooltip" data-placement="top"
                title="Add custome" (click)="openCustomeCategoryField('carrier')">
                <i class="bi bi-plus-circle"></i>
              </button>
            </div>

          </div>
          <div class="row mt-3">
            <div class="col-9 form-group" *ngIf="carrierCustomeCategory">
              <input type="text" class="form-control" (keyup.enter)="addCustomeCategory()" placeholder="Add Custom Categories" [(ngModel)]="customeCategory"
              style="color: black; border: 1px solid #aebecd; border-radius: 4px">
            </div>
            <div class="col-3 form-group" *ngIf="carrierCustomeCategory">
              <button 
              type="button" 
              class="btn btn-primary custome-cat-btn" 
              data-toggle="tooltip" 
              data-placement="top" 
              title="save"
              >
              <i class="bi bi-check2"  (click)="addCustomeCategory()" *ngIf="customeCategory !==''"></i>
              <i class="bi bi-x" (click)="closeCustomeCategoryField()" *ngIf="customeCategory ==''"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- style="margin-top: 15%" -->
    <div class="modal-footer text-center p-0">
      <div class="row m-0" style="width: 100%">
        <div class="col-12 p-0">
          <button type="button" style="height: 53px; border-radius: 9px"
            class="btn btn-primary font-bold width-80 m-t-40 m-b-20 mob-view-btn" (click)="
              opencarrierSizeOfPackage(carrierSizeOfPackage, categorykg.value)
            ">
            Next
          </button>
        </div>
        <div class="col-12 mb-3">
          <a (click)="
          modal.close('Save click'); openPreviousModal(travellingFrequency)
        ">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Size of Package Carrier -->
<ng-template #carrierSizeOfPackage let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-500 headd mob-head-22" id="modal-basic-title">
        <!-- How are you going to travel ? -->
        How will you travel ?
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="reset(); modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 traveling-type-mob-v">
      <div class="row">
        <div style="border: solid 2px #e1e7ec; border-radius: 5px !important"
          *ngFor="let travellingType of travellingTypes2; let i = index" [ngClass]="
            travellingType.clicked
              ? 'col-12 m-b-10 carrier-travellingType'
              : 'col-12 m-b-10 carrier-travellingType1'
          " (click)="getcarrygetTravellingType(travellingType.id)">
          <div class="row">
            <div class="col-3 text-center col-md-2 p-r-0 p-l-21">

              <ngx-shimmer-loading *ngIf="aboutPacsendLCP" [shape]="'rect'" [width]="'56px'" [height]="'56px'">
              </ngx-shimmer-loading>

              <img *ngIf="!aboutPacsendLCP" src="https://pacsend.tech/public/uploads/travelingBy/{{
                  travellingType.image
                }}" class="width-100" />
            </div>
            <div class="col-9 col-md-10 text-left">
              <a #travelling class="mb-0 font-16 font-700 colorBlack">{{travellingType.title}}</a>
              <p class="mb-0 font-14 color-new-figma">
                <!-- Books, Laptops, clothes etc -->
                <!-- {{
                travellingType.description ? travellingType.description : "Books, Laptops, clothes etc" }} -->
                {{travellingType.description}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer text-center p-0">
      <div class="row m-0 w-100">
        <div class="col-12 p-0">
          <button type="button" class="btn btn-primary font-bold m-t-b-20 mob-view-btn h-53 width-90 border-rad-10"
            (click)="openpaymentcarrier(carrierPayment)">
            Next
          </button>
        </div>
        <div class="col-12 mb-3">
          <a (click)="
              modal.close('Save click'); openPreviousModal(carrierPackageInfo)
            ">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Payment -->
<ng-template #carrierPayment let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title w-100 font-21 font-500 headd" id="modal-basic-title">
        Payment
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0 pb-0 payment-mob-v">
      <p class="color-new-figma">How much you will charge per KG?</p>
      <div class="form-group">
        <select style="width: 85px;" [(ngModel)]="carriarAd.currency" class="form-control" id="currency" name="currency">
          <option *ngFor="let currency of currencys" [ngValue]="currency">{{currency}}</option>
        </select>
        <input style="width: calc(100% - 85px);top: -38px;display: block;position: relative;left: 85px;" #amount class="form-control form-custom-control" name="MobileNumber" type="number"
          placeholder="Amount Per Kg" min="0" />
      </div>

      <div class="modal-footer text-center p-0">
        <div class="row m-0 p-0 w-100 p-l-0 p-r-25"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="modal-footer text-center p-0">
      <div class="row m-0 w-100">
        <div class="col-12 p-2" style="margin-bottom: 12px !important">
          <div class="term-ui">

            <div [innerHTML]="dataTerms"></div>

            <p class="terms-heading font-900">Pacsend User Agreement</p>
            <div class="tacbox">
              <div class="input-term">


                <input style=" cursor: pointer !important;" class="termsInput" id="checkbox" type="checkbox"
                  [checked]="!checkbox1" (change)="checkbox1 = !checkbox1" />
              </div>
              <label class="font-14" for="checkbox">
                Check here to indicate that you have read and agree to the
                <a (click)="termsClicked()" class="default-color" target="_blank"
                  href="https://pacsend.com/terms-and-condition"><b><span>Terms and</span> Conditions</b>&nbsp;</a>of
                Pacsend</label>
            </div>
          </div>
        </div>

        <div class="col-12 p-0">
          <button [disabled]="checkbox1" type="button"
            class="btn btn-primary font-bold width-80 m-b-20 mob-view-btn border-rad-10 h-53"
            (click)="opencarrierSuccess(carrierSuccess, amount.value)">
            Next
          </button>
        </div>
        <div class="col-12 mb-3">
          <a (click)="
              modal.close('Save click'); openPreviousModal(carrierSizeOfPackage)
            ">Go Back</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Want To Send Successful -->
<ng-template class="mob-mr-4px" #carrierSuccess let-modal>
  <div class="w-100 w-500" [@zoomIn]="zoomIn">
    <div class="modal-header p-2">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center pt-0 pb-0 p-l-r-10px">
      <h3 class="modal-title w-100 font-21 mb-4 headd">Ad Sent For Approval</h3>

      <ngx-shimmer-loading *ngIf="aboutPacsendLCP" [shape]="'circle'" [width]="'404px'" [height]="'406px'">
      </ngx-shimmer-loading>

      <img *ngIf="!aboutPacsendLCP" src="../assets/verificationSuccessful.webp"
        class="width-50 sucess-img-mob-v sucess-img-mob-v2" />
      <p class="color-new-figma">
        Your data has been posted successfully. We will notify you once your ad is approved by our quality team.
        Meantime sit back and relax.
      </p>
    </div>
    <div class="modal-footer pb-2 p-0">
      <button type="button"
        class="btn btn-primary font-bold width-90 m-t-10 m-b-30 mob-mb-10 border-rad-10 h-53 m-right-5"
        (click)="RouteToAllAds()">
        Close
      </button>
    </div>
  </div>
</ng-template>

<!-- Account Not Login Popup -->
<div id="myNav" class="overlay">
  <i class="fa fa-angle-left modal-angle-left" onclick="closeNav()"></i>
  <div class="overlay-content">
    <div class="row">
      <div class="col-12 text-center">
        <img class="max-w-h-100" src="./../assets/accountImage.png" />
        <p class="myNavNotSignedIn">You are not Signed in!</p>
        <p><a href="#" class="myNavSignIn">Sign in to your Account.</a></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left border-top-grey">
        <ul class="overlay-ul">
          <li class="myNavLi">
            <a href="map.php" class="p-r-10 p-l-10 font-14">Map</a>
          </li>
          <li class="myNavLi">
            <a href="all-ads.php" class="p-r-10 p-l-10 font-14">All Ads</a>
          </li>
          <li class="myNavLi">
            <a href="my-ads.php" class="p-r-10 p-l-10 font-14">My Ads</a>
          </li>
          <li class="myNavLi">
            <a href="chat.php" class="p-r-10 p-l-10 font-14">Chat</a>
          </li>
          <li class="myNavLi">
            <a href="about.php" class="p-r-10 p-l-10 font-14">About</a>
          </li>
          <li class="myNavLi">
            <a href="javascrpt::" data-toggle="modal" data-target="#newAd" class="p-r-10 p-l-10 font-14"
              id="newAdMobile">New Ads</a>
          </li>
        </ul>
      </div>
      <div class="col-12 text-center">
        <a class="btnAccountSignIn color-white" href="login.php"> Sign In</a>
      </div>
    </div>
  </div>
</div>

<!-- Account Login Popup -->
<div id="isLoginNav" class="overlay">
  <i class="fa fa-angle-left modal-angle-left" onclick="closeIsLogin()"></i>
  <div class="overlay-content">
    <div class="row">
      <div class="col-12 text-center">
        <img class="max-w-h-100" src="./../assets/profile-image.png" />
        <p class="isLogin">Hello!</p>
        <p class="isLoginFullname">User Name</p>
        <p class="isLoginUsername">@unique_username</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left mar-top-20-border-grey">
        <ul class="overlay-ul">
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-user"></i> My Details
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-clipboard"></i> My Listings
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-heart"></i> My Wishlist
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-star"></i> Featured Listing
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-th"></i> Subscription Plans
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-cogs"></i> Service Plans
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-bullhorn"></i> Bump Up Ads
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-th-list"></i> Banners
            Advertisment
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-comments"></i> Chat Messages
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-money"></i> Wallet
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-shopping-cart"></i> My Purchases
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-bell"></i> Notifications
          </li>
          <li class="isLoginLi">
            <i class="fa min-width-25-p-r-10 fa-question"></i> Help
          </li>
        </ul>
      </div>
      <div class="col-12 text-center pad-top-20-border-grey">
        <button class="btn btnAccountSignIn">Sign Out</button>
      </div>
    </div>
  </div>
</div>

<!-- Account Profile -->
<div id="accountProfile" class="overlay overflow-y-hidden">
  <div class="overlay-content m-t-0">
    <div class="row bgcolor-5">
      <div class="col-12 text-left">
        <i class="fa fa-angle-left modal-angle-left color-white" onclick="closeProfileNav()"></i>
      </div>
      <div class="col-12 text-center m-b-20">
        <img class="max-w-h-100 circleViewProfile" src="./../assets/profile-image.png" />
        <p class="isLoginFullname color-white">User Name</p>
        <p class="isLoginUsername color-white">@unique_username</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left margin-10">
        <label class="labelviewProfile">Email</label>
        <h6 class="hsixviewProfile">
          user@email.com
          <hr class="hrviewProfile" />
        </h6>
        <label class="labelviewProfile">Mobile Number</label>
        <h6 class="hsixviewProfile">
          +92 335 XXXXXXX
          <hr class="hrviewProfile" />
        </h6>
        <label class="labelviewProfile">City</label>
        <h6 class="hsixviewProfile">
          Karachi
          <hr class="hrviewProfile" />
        </h6>
        <label class="labelviewProfile">Country</label>
        <h6 class="hsixviewProfile">
          Pakistan
          <hr class="hrviewProfile" />
        </h6>
        <label class="labelviewProfile">Address</label>
        <h6 class="hsixviewProfile">
          Lorem ipsum dolor sitamet, consectur adipciring elit, sed do el. Lorem
          ipsum dolor sitamet, consectur adipciring elit, sed do el. Lorem ipsum
          dolor sitamet, consectur adipciring elit, sed do el
          <hr class="hrviewProfile" />
        </h6>
      </div>
    </div>
  </div>
</div>

<!-- Feedback -->
<div id="feedbackPopup" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h3 class="modal-title m-b-20">Feedback</h3>

        <div class="row">
          <div class="col-12 m-b-10 text-center">
            <div class="row">
              <div class="col-12 m-t-10 m-b-10">
                <p>
                  How was your experience in dealing with
                  <span class="font-bold">Rauf Lala</span>?
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <img src="./../assets/angryemoji.png" class="width-100" />
              </div>
              <div class="col">
                <img src="./../assets/angryemoji.png" class="width-100" />
              </div>
              <div class="col">
                <img src="./../assets/angryemoji.png" class="width-100" />
              </div>
              <div class="col">
                <img src="./../assets/angryemoji.png" class="width-100" />
              </div>
              <div class="col">
                <img src="./../assets/lovedemoji.png" class="width-100" />
              </div>
            </div>
            <div class="row m-t-10">
              <div class="col text-center">
                <span class="fa fa-star checked"></span>
              </div>
              <div class="col text-center">
                <span class="fa fa-star"></span>
              </div>
              <div class="col text-center">
                <span class="fa fa-star checked"></span>
              </div>
              <div class="col text-center">
                <span class="fa fa-star"></span>
              </div>
              <div class="col text-center">
                <span class="fa fa-star checked"></span>
              </div>
            </div>
            <div class="row m-b-10 m-t-10">
              <div class="col-12">
                <textarea type="text" class="form-control text-left" placeholder="Type Something.."
                  readonly=""></textarea>
              </div>
            </div>
          </div>
        </div>
        <button data-dismiss="modal" data-toggle="modal" data-target="#"
          class="btn btn-primary font-bold width-100 m-t-10 m-b-10" type="submit">
          submit
        </button>
      </div>
    </div>
  </div>
</div>
<!-- feedback End  -->
<!-- Verification PIN -->
<div id="verificationPin" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h3 class="modal-title m-b-20">Verification PIN</h3>

        <div class="row">
          <div class="col-12 m-b-10 text-center">
            <div class="row">
              <div class="col-12">
                <p>Compare this PIN with Sender</p>
              </div>
            </div>
            <div class="row m-b-10 m-t-10">
              <div class="col-12">
                <input type="text" class="form-control text-center" name="" value="98dn56Dhy&6cby" readonly="" />
              </div>
            </div>
          </div>
        </div>
        <button data-dismiss="modal" data-toggle="modal" data-target="#"
          class="btn btn-primary font-bold width-100 m-t-10 m-b-10 h-53 border-rad-10" type="submit">
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Attention Required -->
<div id="attentionRequired" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h3 class="modal-title m-b-20">Attention Required</h3>

        <div class="row">
          <div class="col-12 m-b-10 text-center">
            <div class="row">
              <div class="col-12">
                <p>
                  Are you sure tou want to <br />deal with
                  <span class="font-bold">Rauf Lala</span> ?
                </p>
              </div>
            </div>
          </div>
        </div>
        <button data-dismiss="modal" data-toggle="modal" data-target="#"
          class="btn btn-primary font-bold width-100 m-t-10" type="submit">
          Yes, I Accept Rauf's offer
        </button>
        <button data-dismiss="modal" data-toggle="modal" data-target="#"
          class="btn btn-secondary font-bold width-100 m-t-10 m-b-10" type="submit">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<div id="cancellationRequest" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h3 class="modal-title m-b-20">Cancellation Request Sent</h3>
        <img src="./../assets/verificationSuccessful.png" class="width-50" />
        <p class="m-b-10 m-t-20">
          Your request to cancel the deal has been sent successffully. The deal
          will be cancelled once <span class="font-bold">Abdul Karim</span> has
          accepted the request.
        </p>
        <button data-dismiss="modal" class="btn btn-primary font-bold width-100 m-t-10 m-b-10" type="submit">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>

<div id="canceltheDeal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h3 class="modal-title m-b-20">Attention Required</h3>

        <div class="row">
          <div class="col-12 m-b-10 text-center">
            <div class="row">
              <div class="col-12">
                <p class="colorGray">
                  Are you sure you want to <br />deal with
                  <strong>Rauf Lala</strong> ?
                </p>
              </div>
            </div>
          </div>
        </div>
        <button data-dismiss="modal" data-toggle="modal" data-target="#cancellationRequest"
          class="btn btn-primary font-bold width-100 m-t-10" type="submit">
          Yes, I cancel the deal
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Account Sign In -->
<div id="SignInNav" class="overlay">
  <i class="fa fa-angle-left modal-angle-left" onclick="closeSignInNav()"></i>
  <div class="overlay-content">
    <div class="row">
      <div class="col-12 text-center m-t-40">
        <p class="signInHow">HOW WOULD YOU?</p>
        <p class="signIn">SIGN IN?</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btnAccountSignUp">
          <img src="./../assets/facebook.png" /> SIGN IN WITH FACEBOOK
        </button>
        <button class="btn btnAccountSignUp">
          <img src="./../assets/google.png" /> SIGN IN WITH GOOGLE
        </button>
        <button class="btn btnAccountSignUp">
          <img src="./../assets/cell-phone.png" /> SIGN IN WITH MOBILE
        </button>
        <button class="btn btnAccountSignUp">
          <img src="./../assets/envelope.png" /> SIGN IN WITH EMAIL
        </button>
        <button class="btn btnAccountHelp">HELP?</button>
      </div>
    </div>
  </div>
</div>

<!-- Fixed Button -->
<div id="fixedButton" class="display-none">
  <button class="sellnow"><i class="fa fa-download"></i> Sell Now</button>
</div>
<!-- Download App Modal -->
<div class="modal" id="downloadApp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-download-app" role="document">
    <div class="modal-content border-radius-border-zero backgroundRed">
      <div class="modal-body padding-5">
        <div class="row">
          <div class="col-3 font-10 color-white">For Better Experience</div>
          <div class="col-6 font-10">
            <button class="btndownloadApp">Download App Now</button>
          </div>
          <div class="col-3 font-10">
            <button class="btnNotNow" data-toggle="modal" data-target="#downloadApp">
              Not Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ////////////////////////////////verify modal////////////////////////////////////////////////////////////////////////////// -->

<div class="w-100">
  <div class="w-100">
    <ng-template #verifyProfileStepOne let-modal>
      <div class="ad-left position-absolute top-0 right-44">
        <img *ngIf="googleAdsImageVerifyRight != undefined" class="w-100" [src]="
            'https://pacsend.tech/public/uploads/slots/' +
              googleAdsImageVerifyRight | byPassSecurityUrl
          " />
        <div *ngIf="googleAdsScript != undefined" [innerHTML]="googleAdsScript | byPassSecurityScript"></div>
      </div>

      <div class="ad-right position-absolute top-0 left-44">
        <img *ngIf="googleAdsImageVerifyRight != undefined" class="w-100" [src]="
            'https://pacsend.tech/public/uploads/slots/' +
              googleAdsImageVerifyRight | byPassSecurityUrl
          " />
        <div *ngIf="googleAdsScript != undefined" [innerHTML]="googleAdsScript | byPassSecurityScript"></div>
      </div>
      <div class="w-100 w-500" [@zoomIn]="zoomIn">
        <div class="modal-header">
          <h4 class="modal-title font-25 mt-2 font-500 w-100 verify-your-self-h mob-responsive-verify-text"
            id="modal-basic-title">
            Verify Yourself
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center pt-0 pb-0 verify-card-mob-responsive">
          <div class="numberCircleRed mob-view-red">1</div>
          <div class="numberCircleWhite mob-view-red">2</div>
          <div class="numberCircleWhite mob-view-red">3</div>
          <p class="color-figma-photo text-center mt-4 mb-3 color-figma-photo-mob">
            Upload a selfie with your id card
          </p>
          <div class="custom-dropzone custom-dropZone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event,verifyProfileStepTwo)">
            <ngx-dropzone-label>
              <svg width="80" height="64" viewBox="0 0 80 64" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M44 20V44H36V20H24L40 0L56 20H44ZM8 56H72V28H80V56C80 60.412 76.412 64 72 64H8C3.588 64 0 60.412 0 56V28H8V56Z"
                  fill="#AEBECD" />
              </svg>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of uploadfile" [file]="f"
              [removable]="true" (removed)="onRemove(f)" click="UploadImage()">
            </ngx-dropzone-image-preview>
          </div>
        </div>
        <div class="modal-footer pb-2 p-0">
          <button type="button" [attr.disabled]="uploadfile.length == 0 ? '' : null"
            class="btn btn-primary font-600 m-t-20 mb-4 h-56 next-btnn verify-btn-card-mob-responsive"
            (click)="verificationstepTwo(verifyProfileStepTwo)">
            Next
          </button>
        </div>
      </div>
    </ng-template>

    <!-- Verification Step 2 -->
    <ng-template #verifyProfileStepTwo let-modal>
      <div class="w-100 w-500" [@zoomIn]="zoomIn">
        <div class="modal-header">
          <h4 class="modal-title font-21 font-500 mt-2 verify-your-self-h w-100 media-class mob-responsive-verify2-text"
            id="modal-basic-title">
            Verify Yourself
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center pt-0 pb-0 verify-card-mob-responsive">
          <div class="numberCircleRed mob-view-red">1</div>
          <div class="numberCircleRed mob-view-red">2</div>
          <div class="numberCircleWhite mob-view-red">3</div>

          <p
            class="color-figma-photo text-center mt-4 mb-3 ml-4 mr-4 color-figma-photo-mob">
            {{
            !disablefrontbtn
            ? "Upload your ID card front image"
            : "Upload your ID card back image"
            }}
          </p>
          <!-- <p *ngIf="frontselfiearray.length && backselfiearray.length"
            class="color-figma-photo text-center mt-4 mb-3 ml-4 mr-4 color-figma-photo-mob">
            Please "click" next to add your National ID Number.
          </p> -->
          <div class="row">
            <div class="col-12 m-b-20" >
              <button class="btn w-30 mr-2 mob-responsive-btn-font"
                [ngClass]="!disablefrontbtn ? 'btn-primary' : 'deactive-Back'" (click)="frontimage()">
                Front
              </button>
              <button [ngClass]="!disabledbackbtn ? 'btn-primary' : 'deactive-Back'"
                class="btn w-30 mob-responsive-btn-font" (click)="backimage()">
                Back
              </button>
            </div>
          </div>
          <div [hidden]="frontSelfie">
            <div class="custom-dropzone custom-dropZone" ngx-dropzone [accept]="'image/*'"
              (change)="onSelectFront($event)">
              <ngx-dropzone-label><svg width="80" height="64" viewBox="0 0 80 64" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M44 20V44H36V20H24L40 0L56 20H44ZM8 56H72V28H80V56C80 60.412 76.412 64 72 64H8C3.588 64 0 60.412 0 56V28H8V56Z"
                    fill="#AEBECD" />
                </svg>
              </ngx-dropzone-label>
              <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of frontselfiearray"
                [file]="f" [removable]="true" (removed)="onRemovefront(f)">
              </ngx-dropzone-image-preview>
            </div>
          </div>
          <div [hidden]="backSelfie">
            <div class="custom-dropzone custom-dropZone" ngx-dropzone [accept]="'image/*'"
              (change)="onSelectBack($event,verifyProfileStepThree)">
              <ngx-dropzone-label><svg width="80" height="64" viewBox="0 0 80 64" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M44 20V44H36V20H24L40 0L56 20H44ZM8 56H72V28H80V56C80 60.412 76.412 64 72 64H8C3.588 64 0 60.412 0 56V28H8V56Z"
                    fill="#AEBECD" />
                </svg>
              </ngx-dropzone-label>
              <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of backselfiearray"
                [file]="f" [removable]="true" (removed)="onRemoveback(f)">
              </ngx-dropzone-image-preview>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0">
          <button type="button"
            class="btn btn-primary font-600 m-t-14 mt-4 h-56 next-btnn verify-btn-card-mob-responsive"
            (click)="verificationstepThree(verifyProfileStepThree)">
            Next
          </button>
          <button type="button" class="btn btn-primary font-600 m-t-20 mb-4 h-56 next-btnn verify-btn-card-mob-responsive"
            (click)="verificationstepOne(verifyProfileStepOne)">
            Back
          </button>
        </div>
      </div>
    </ng-template>

    <!-- Verification Step 3 -->
    <ng-template #verifyProfileStepThree let-modal>
      <div class="w-100" [@zoomIn]="zoomIn">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div [formGroup]="form" class="modal-body text-center pt-0 pb-0 verify-card-mob-responsive">
          <h3 class="font-23 font-500 m-b-10 mob-responsive-head desktop-responsive-head">
            Verify Yourself
          </h3>
          <div class="numberCircleRed mob-view-red m-b-5">1</div>
          <div class="numberCircleRed mob-view-red m-b-5">2</div>
          <div class="numberCircleRed mob-view-red m-b-5">3</div>
          <p class="color-figma-photo text-center mt-4 mb-4 ml-3 mr-3 color-figma-photo-mob">
            Please enter your National ID Number
          </p>
          <div class="form-group m-t-15">
            <!-- appNumberOnly -->
            <input class="form-control form-custom-control form-control-mobb" [(ngModel)]="nicNo" formControlName="CNIC"
               maxlength="15" type="text" pattern="\d*" placeholder="ID number" />
          </div>
        </div>
        <div class="modal-footer p-0">
          <button type="button"
            class="btn btn-primary font-600 m-t-10 h-56 next-btnn verify-btn-card-mob-responsive"
            (click)="verificationsuccessfull(verificationSuccessful)">
            Verify
          </button>
          <button type="button" class="btn btn-primary font-600 m-t-20 mb-4 h-56 next-btnn verify-btn-card-mob-responsive"
          (click)="verificationstepTwo2(verifyProfileStepTwo)">Back</button>
        </div>
      </div>
    </ng-template>

    <ng-template #verificationSuccessful let-modal>
      <div class="w-100" [@zoomIn]="zoomIn">
        <div class="modal-header">
          <h4 class="modal-title font-21 font-500 w-100" id="modal-basic-title">
            <!-- Verification Successfull -->
            Documents Upload Successful
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center pt-0 pb-0 verify-card-mob-responsive pl-3 pr-3">
          <img src="../../../assets/verificationSuccessful.png" class="img-responsive w-50" />
          <p class="font-16 color-figma-photo text-center mt-2 mb-2 color-figma-photo-mob">
            <!-- Your data has been sent successfully for verification. -->
            Your documents will be checked and account will be verified shortly.
          </p>
        </div>
        <div class="modal-footer p-0">
          <button type="button"
            class="btn btn-primary font-600 mb-4 m-t-10 h-56 next-btnn verify-btn-card-mob-responsive"
            (click)="modal.close('Save click'); hidebutton()">
            Continue
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #navigationRestricted let-modal>
  <div class="w-100" [@zoomIn]="zoomIn">
    <div class="modal-header">
      <h4 class="modal-title font-21 font-500 w-100" id="modal-basic-title">
        Please wait
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center pt-0 pb-0">
      <img src="{{settingImageUrl + unverified_image}}" class="img-responsive w-50" />
      <p class="color-figma-photo text-center mt-2 mb-2">
        {{unverified_text}}
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary font-600 width-100 m-t-10 h-56" [routerLink]="['/all-ads']"
        (click)="modal.close('Save click'); allAdsActive(); hidebutton()">
        Home
      </button>
    </div>

  </div>
</ng-template>
<ng-template #navigationLogin let-modal>
  <div class="w-100" [@zoomIn]="zoomIn">
    <div class="modal-header mt-4">
      <h4 *ngIf="!userLogout" class="modal-title font-21 font-500 w-100" id="modal-basic-title">
        Hurray !!! Verification Successful
      </h4>
    </div>
    <div *ngIf="!userLogout" class="modal-body text-center pt-0 pb-0">
      <img src="../../../assets/verificationSuccessful.png" class="img-responsive w-50" />
      <p class="color-figma-photo text-center mt-2 mb-2">
        Your account is verified
      </p>
    </div>

    <div *ngIf="userLogout" class="modal-body text-center pt-0 pb-0">
      <img src="../../../assets/verificationSuccessful.png" class="img-responsive w-50" />
      <p class="color-figma-photo text-center mt-2 mb-2">
        You have to login again
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary font-600 width-100 m-t-10 h-56" (click)="backToLogin()">
        Let's Login Again
      </button>
    </div>
  </div>
</ng-template>
<ng-template class="bttest" #CropperModal let-modal>
  <div class="crop-modal" [@zoomIn]="zoomIn">
    <div class="modal-header d-flex justify-content-between">
      <h4 class="modal-title font-20" id="modal-basic-title">
        Upload Images <small class="text-danger ml-2 font-14">You can upload 5 images</small>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="
          showImages = [];
          images = [];
          croperModalDismis(CropperModal);
          modal.dismiss('Cross click')
        ">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body py-4 border-top border-bottom">
      <div class="row m-b-20">
        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <div class="form-group" *ngIf="showImages.length < 5">
            <label for="file">File</label>
            <input id="file" type="file" name="file" [(ngModel)]="senderAd.image" multiple="multiple"
              (change)="onFileChange($event)" accept="image/x-png,image/gif,image/jpeg" />
          </div>
          <div class="image-area col-12 col-xs-12 col-sm-12 col-md-12">
            <div class="image-list">
              <div *ngFor="let url of showImages; let i = index" class="position-relative">
                <div (click)="removeSelectedImage(i, url)" class="cros-image-black"> 
                  <i class="fa fa-times-circle remove-images"></i>
                </div>
                <img [src]="url" class="img-responsive sender-images" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer p-3 bg-light text-right">
      <button class="btn btn-primary px-4 py-2 btn-sm" [disabled]="showImages.length > 5" (click)="
          sendImages(packageInfo, whereAreYouTravellingFrom);
          modal.dismiss('Cross click')
        ">
        Submit
      </button>
    </div>
  </div>
</ng-template>