<section id="wrapper">
  <div class="login-register login-register-bg-color">
    <div class='row'>
      <div class="col-md-4"></div>
      <div class="col-12 col-md-4 text-center">
        <img class="img-responsive max-width-30p m-b-18" [src]="'../../../assets/logo resize-02.webp'">
        <!-- <img class="img-responsive max-width-30p m-b-18" [src]="'https://pacsend.tech/public/uploads/setting/Pacsend%20logo%20resize-02.png'"> -->
      </div>
      <div class="col-md-4"></div>
    </div>

    <div class="login-box card">

      <div class="card-body">

        <div class="col-12">
          <h3 class="text-center m-b-20 font-17 font-600">Sign up for your account </h3>
        </div>

<form [formGroup]="signUpForm" (submit)="onSubmit()">

  <div class="col-12 m-b-20">
    <input type="text" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'ae'}" maxlength="13"
    class="form-custom-control number-field" formControlName="contact" />
        </div>
        <div class="col-12">
          <div class="form-group">

            <select #country (change)="getStates(country.value)" class="form-custom-control" type="text"
            placeholder="Select Country" formControlName="country_id">
              <option value="" selected disabled hidden>Select Country</option>
              <option *ngFor="let country of countries" value="{{country.id}}">{{country.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <ngx-ui-loader [bgsColor]="'#E32025'" [bgsSize]="40" [bgsType]="'three-bounce'" [loaderId]="'select-1'">
            </ngx-ui-loader>
            <select #staty (change)="getCity(staty.value)" class="form-custom-control" type="text"
            aria-placeholder="Select Country" formControlName="state_id" place>
            <option value="" selected disabled hidden>Select State</option>
            <option *ngFor="let state of states" value="{{state.id}}">{{state.name}}</option>
          </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <ngx-ui-loader [bgsColor]="'#E32025'" [bgsSize]="40" [bgsType]="'three-bounce'" [loaderId]="'select-2'">
            </ngx-ui-loader>
            <select #state class="form-custom-control" type="text" aria-placeholder="Select Country"
              formControlName="city_id">
              <option value="" selected disabled hidden>Select City</option>
              <option *ngFor="let city of cities" value="{{city.id}}">{{city.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <ngx-ui-loader [bgsColor]="'#fff'" [bgsSize]="40" [bgsType]="'three-bounce'" [loaderId]="'saveButton'">
          </ngx-ui-loader>

          <div class="form-group">

            <button class="btn btn-primary width-100"  type="submit">Sign Up</button>
          </div>
        </div>
      </form>
        <form class="form-horizontal" id="recoverform" action="index.html">
          <div class="form-group ">
            <div class="col-xs-12">
              <h3>Recover Password</h3>
              <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
            </div>
          </div>
          <div class="form-group ">
            <div class="col-xs-12">
              <input class="form-control" type="text" required="" placeholder="Email">
            </div>
          </div>
          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
              type="submit">Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

