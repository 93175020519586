<style>
  ::ng-deep share-buttons .sb-material-dark .sb-wrapper {
    background-color: white !important;
    border-radius: 50% !important;
    padding: 5px;
    font-size: 13px !important;
    width: 30px;
    height: 54px !important;
    transition: 0.3s;
    text-align: center;
    margin: 5px 2px;
    box-shadow: 0px 1px 6px 1px #cac7c7 !important;
  }

  ::ng-deep share-buttons .sb-material-dark .sb-wrapper:hover {
    background-color: red !important;
    border-radius: 50% !important;
    padding: 5px;
    font-size: 13px !important;
    width: 30px;
    height: 54px !important;
    transition: 0.3s;
    text-align: center;
    margin: 5px 2px;
    color: white !important;
    box-shadow: 0px 1px 6px 1px #cac7c7 !important;
  }

  ::ng-deep share-buttons .sb-wrapper .sb-inner .sb-content {
    color: red;
    border-radius: 50% !important;
  }

  ::ng-deep share-buttons .sb-wrapper .sb-inner .sb-content:hover {
    color: white;
    border-radius: 50% !important;
  }
</style>
<div class="" style="background-color: #ecf0f3; margin-top: 5%">
  <div class="container">
    <div class="row">
      <div
      *ngIf="!hideLCP" class="
      mx-2
      mt-2
      col-12
      d-flex
      justify-content-center
      align-items-center
    ">
      <ngx-shimmer-loading *ngIf="googleAdsImage != undefined" [shape]="'rect'" [width]="'1200px'" [height]="'130px'">
      </ngx-shimmer-loading>
      </div>
      <div *ngIf="hideLCP" class="
          mx-2
          mt-2
          col-12
          d-flex
          justify-content-center
          align-items-center
        ">
        <img *ngIf="googleAdsImageCarrierTop != undefined" class="w-100" [src]="
            'https://pacsend.tech/public/uploads/slots/' +
              googleAdsImageCarrierTop | byPassSecurityUrl
          " />
        <div *ngIf="googleAdsScript != undefined" [innerHTML]="googleAdsScript | byPassSecurityScript"></div>
      </div>

      <div class="col-md-8 m-t-b-10">
        <div class="card m-b-10">
          <div class="card-body">
            <!-- ////////////////  TRIP INFO //////////// -->
            <div class="row">
              <div class="col-md-6 col-12">
                <h4>
                  <!-- <strong>Trip Info</strong> -->
                  <strong>{{adData?.title}}</strong>
                </h4>
              </div>
              <div class="col-md-6 col-12 text-right">
                <div class="row">
                  <div class="col-md-12">
                    <i class=""></i>
                    <div class="
                        d-flex
                        justify-content-end
                        w-100
                        align-items-center
                        mob-v-margin
                      ">
                      <div class="CardIcons mx-2" (click)="toggleShareButton()">
                        <a> <i class="fa fa-share-alt"></i></a>
                      </div>
                      
                      <div *ngIf="loginuser && (adData && adData?.creater.id != userId && !alreadyReported)" (click)="GetaddRepot()" class="CardIcons mr-2 dropdown show">
                        <a>
                          <i class="fas fa-flag colorBlack"></i></a>
                      </div>
                  

                      <div class="mr-2 CardIcons" *ngIf="loginuser && (adData && adData?.creater.id != userId && !myStatus)"
                        (click)="updateWishlist()">
                        <i class="fa fa-heart text-dark cursor-pointer"></i>
                      </div>

                      <div class="mr-2 CardIcons"  *ngIf="loginuser && (adData && adData?.creater.id != userId && myStatus)"
                        (click)="updateWishlist()">
                        <i class="fa fa-heart colorRed cursor-pointer"></i>
                      </div>

                  

                    </div>
                    <div *ngIf="hideShareButtons" class="share-area">
                      <div class="row">
                        <div class="col-12">
                          <p class="mt-2 mb-1 color-figma-gray text-center">
                            Share on Social Media
                          </p>
                          <div class="sb-group">
                            <a target="_blank" href="https://www.facebook.com/sharer.php?u={{page_url}}">
                              <div style="margin-top:10px;font-size: 16px;" class="sb-icon ng-star-inserted">
                                  <svg role="img" class="svg-inline--fa fa-facebook-f fa-w-9 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512">
                                    <path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"></path>
                                  </svg>
                              </div>
                            </a>
                            <a target="_blank" href="https://twitter.com/share?url={{page_url}}">
                              <div style="margin-top:10px;font-size: 16px;" class="sb-icon ng-star-inserted">
                                <svg role="img" class="svg-inline--fa fa-twitter fa-w-16 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                </svg>
                              </div>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/shareArticle?url={{page_url}}">
                              <div style="margin-top:10px;font-size: 16px;" class="sb-icon ng-star-inserted">
                                <svg role="img" class="svg-inline--fa fa-linkedin-in fa-w-14 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                  <path fill="currentColor" d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z"></path>
                                </svg>
                              </div>
                            </a>
                            <a target="_blank" href="https://wa.me/?text={{page_url}}">
                              <div style="margin-top:10px;font-size: 16px;" class="sb-icon ng-star-inserted">
                                <svg role="img" class="svg-inline--fa fa-whatsapp fa-w-14 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                  <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                                </svg>
                              </div>
                            </a>
                            <a target="_blank" href="mailto:?subject=&amp;body={{page_url}}">
                              <div style="margin-top:10px;font-size: 16px;" class="sb-icon ng-star-inserted">
                                <svg role="img" class="svg-inline--fa fa-envelope fa-w-16 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
                                </svg>
                              </div>
                            </a>
                            <a href="javascript:void(0);" (click)="copyMessage()">
                              <div style="margin-top:10px;font-size: 16px;" class="sb-icon ng-star-inserted">
                                <svg role="img" class="svg-inline--fa fa-link fa-w-16 fa-fw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path fill="currentColor" d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
                                </svg>
                              </div>
                            </a>
                          </div>
                          <!-- <share-buttons [theme]="'material-dark'" [include]="[
                              'facebook',
                              'twitter',
                              'linkedin',
                              'whatsapp',
                              'email',
                              'copy'
                            ]" [size]="1" (click)="check('open')">
                          </share-buttons> -->
                        </div>
                      </div>
                    </div>

                    <div *ngIf="hideReport" class="share-area flag-popup">
                      <div class="row">
                        <div class="col-12">
                          <h4 class="modal-title w-100 font-21 font-500 modal-basic-title" id="modal-basic-title">
                            Add Report
                          </h4>
                          <div class="col-12 m-b-10" *ngFor="let report of reports; let i = index"
                          (click)="travelTypeSelect(report.id);" style="padding: 8px 0px; border-radius: 5px !important"
                          [ngStyle]="{
                          border: travelType[report.id] ? '2px solid #da2926' : '2px solid #E1E7EC'
                        }">
                          <div class="row">
                            <div class="col-12 text-center">
                              <a style="color: #202833 !important" class="mb-0 font-16 font-700 mob-font-txt-color">{{
                                report.title }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer pb-1" *ngIf="loginuser">
                          <button type="button"
                            class="btn btn-primary font-bold width-100 m-t-10 m-b-10 h-53 border-rad-10" (click)="
                            adReport(selectedId)">
                            SUBMIT
                          </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-t-b-20 backgroundWhite m-b-15">
              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">From</h6>
              </div>
              <div class="col-8 col-md-10 text-left m-t-10">
                <h6 class="sender-inner-ad-desc locations">
                  <!-- (click)="openLocation(carrierfromlocation)" -->
                  <!-- , '_blank' -->
                  <a href="https://www.google.com/maps/search/?api=1&query={{carrierfromlocation}}" target="_blank">{{ carrierfromlocation }}</a>
                </h6>
              </div>

              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">To</h6>
              </div>

              <div class="col-8 col-md-10 text-left m-t-10">
                <h6 class="sender-inner-ad-desc locations">
                  <!-- (click)="openLocation(carriertolocation)" -->
                  <a href="https://www.google.com/maps/search/?api=1&query={{carriertolocation}}" target="_blank">{{ carriertolocation }}</a></h6>
              </div>
              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">Travels on</h6>
              </div>
              <div class="col-8 col-md-10 text-left m-t-10">
                <h6 class="sender-inner-ad-desc">
                  {{ adData?.travelling_frequency | formatdates }}
                </h6>
              </div>
              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">Trip Time</h6>
              </div>
              <div class="col-8 col-md-10 text-left m-t-10">
                <h6 class="sender-inner-ad-desc">
                  {{ fromtime }} - {{ totime }}
                </h6>
              </div>
            </div>

            <!-- /////////////////// OTHER INFO //////////////////// -->

            <div class="col-md-6 col-12 pl-0">
              <h4>
                <strong>Other Info</strong>
              </h4>
            </div>
            <div class="row p-t-b-20 backgroundWhite m-b-15">
              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">Capacity</h6>
              </div>
              <div class="col-8 col-md-10 text-left m-t-10">
                <h6 class="sender-inner-ad-desc">{{ weight }} KGs</h6>
              </div>

              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">Categories</h6>
              </div>
              <div class="col-8 col-md-10 text-left m-t-10">
                <span *ngFor="let item of travellingTypes" class="sender-inner-ad-desc">{{ item.type_name }} ,
                </span>
              </div>

              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">Travelling By</h6>
              </div>
              <div class="col-8 col-md-10 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">
                  <img style="max-width: 25px" src="https://pacsend.tech/public/uploads/travelingBy/{{
                      adData?.traveling_by_image
                    }}" alt="" />
                  <span class="sender-inner-ad-desc ml-1">{{
                    adData?.traveling_by
                    }}</span>
                </h6>
              </div>

              <div class="col-4 col-md-2 text-left m-t-10">
                <h6 class="sender-inner-ad-title">Cost Per Kg</h6>
              </div>
              <div class="col-8 col-md-10 text-left m-t-10">
                <h6 class="sender-inner-ad-desc">
                  {{ (+adData?.payment).toFixed(0) }} {{adData.currency}}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 m-t-b-10">
        <div class="row">
          <div class="col-md-12">
            <!-- ////////////////////// USER CARD /////////////////////// -->
            <div class="card" *ngIf="!interestedcard && initerestedlist.length > 0">
              <div class="card-body">
                <h2 class="
                    mb-3
                    color-black
                    font-weight-bold
                    intrested-user-heading
                  ">
                  Interested Senders
                </h2>
                <div class="row">
                  <div *ngFor="let item of initerestedlist  let i=index" class="col-12">
                    <div *ngIf="item?.is_offer == 1" class="row no-gutters">
                      <div class="col-2 mb-2">
                        <div class="row">
                          <div class="col-7">                           
                            <img (click)="ClickDelieveryUser(item, item.user_id,i)"
                             [src]="item.image? imageBaseUrl +item.image:'../../../../assets/default.jpg'" class="rounded-circle mb-1" />
                          </div>
                          <div class="col-5"></div>
                        </div>
                      </div>
                      <div class="col-10 mt-2">
                        <div class="row">
                          <div class="col-10">
                            <a (click)="ClickDelieveryUser(item, item.user_id,i)" class="sender-inner-ad-desc">{{item.fname }} {{ item.lname }}</a>
                          </div>
                          <div *ngIf="clicked==i" class="col-2">
                            <i class="intrested-user-icon-red far fa-check-circle m-0"></i>
                          </div>
                          <div *ngIf="clicked!=i" class="col-2">

                            <i class="intrested-user-icon far fa-check-circle m-0"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <div class="card" *ngIf="adData">
              <div class="card-body">
                <div class="row">
                  <div class="col-8 pr-0">
                    <h6 class="sender-inner-ad-title-1 mt-1">
                      <strong>{{ adData?.first_name}}&nbsp;{{adData?.last_name}}</strong>
                    </h6>

                    <div class="star-container">
                      <ngx-stars color="#fdcc0d" [size]="1" [readonly]="true" [size]="4" [maxStars]="5"
                        [initialStars]='adData?.avgFeedBack'></ngx-stars>
                    </div>

                    <h6 class="line-height-30 font-14 mt-1">
                      <span class="sender-inner-ad-desc-1">Active since</span>&nbsp;<span
                        class="sender-inner-ad-desc"><strong>{{adData?.date | formatdates}}</strong></span>
                    </h6>
                    <h6 class="sender-inner-ad-desc-1">
                      Packages Sent -
                      <strong class="sender-inner-ad-desc">{{packagesSent}}</strong>
                    </h6>
                    <h6 class="sender-inner-ad-desc-1">
                      Packages Carried -
                      <strong class="sender-inner-ad-desc">{{packagesCarried}}</strong>
                    </h6>
                        </div>
                  <div class="col-4 pl-0">
                    <img [src]="adData?.creater?.custom_profile_photo? imageBaseUrl + adData?.creater?.custom_profile_photo: adData['user_image']? imageBaseUrl + adData['user_image']:'../../../../assets/default.jpg'" style="
                        max-width: 104%;
                        width: 102%;
                        height: 114px;
                        border-radius: 13%;
                        box-shadow: 0px 0px 9px 0px #c3c6ca;
                      " />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <ngx-shimmer-loading *ngIf="googleAdsImage != undefined && !hideLCP"  [shape]="'rect'" [width]="'400px'" [height]="'300px'">
              </ngx-shimmer-loading>
              <img *ngIf="googleAdsImage != undefined && hideLCP" class="w-100" [src]="
                  'https://pacsend.tech/public/uploads/slots/' + googleAdsImage
                    | byPassSecurityUrl
                " />
              <div *ngIf="googleAdsScript != undefined" [innerHTML]="googleAdsScript | byPassSecurityScript"></div>
            </div>

            <div class="card" *ngIf="showpointofContact">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <h6 class="sender-inner-ad-desc">
                      <strong>Point of contact</strong>
                    </h6>
                    <a><i class="fa fa-user"></i><span style="font-size: 14px; margin-left: 10px">{{pointofcontactname}}</span></a><br />
                    <a><i class="fa fa-mobile"></i><span style="font-size: 14px; margin-left: 12px">+92 &nbsp;{{pointofcontactnumber }}</span></a>
                  </div>
                </div>
              </div>
            </div>
            <!-- adData.carrier_feedbacks!=null -->
            <div class="card" id="targetdiv" *ngIf="
                adData?.user_id == userId &&
                senderDetail != undefined &&
                senderDetail
              ">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <h6 class="sender-inner-ad-desc">
                      <strong>Feedback</strong>
                    </h6>
                    <div>
                      <ngx-stars [readonly]="true" [maxStars]="5" color="#fdcc0d"
                        [initialStars]="senderDetail.rating" [size]="1"></ngx-stars>
                    </div>
                    <h6 class="sender-inner-ad-desc">
                      <strong>Comment</strong>
                    </h6>
                    <div>
                      <span>{{senderDetail?.title | slice: 0:30}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" *ngIf="showCancelCard">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12">
                    <h6 class="color-figma-black font-15 font-500 line-height-30">
                      <strong>Cancellation request</strong>
                    </h6>
                    <span class="font-14 color-figma-new-gray font-500">
                      You have requested for deal cancellation. Deal is
                      temporarily stopped untill {{ cancelDealWith }} responds
                      to the request.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(!hideconenct && btntextcarry != '') && this.adData?.is_cancel_status !=1" class="row">
              <div class="col-12 m-t-20" *ngIf="loginuser">
                <button id="button" [disabled]="disbaled" style="background: #e32025; border-color: cf1d25"
                  (click)="postCarryConnect()" class="btn btn-primary font-bold width-100" type="submit">
                  {{ btntextcarry }}
                </button>
              </div>
            </div>
            <div *ngIf="!hideconenct && (this.adData?.is_accepted == null || this.adData?.is_connected == 0 || this.adData?.is_cancel_status ==1)" class="row">
              <div class="col-12 m-t-20" *ngIf="loginuser">
                <button id="button" [disabled]="disbaled" style="background: #e32025; border-color: cf1d25"
                  (click)="postCarryConnect()" class="btn btn-primary font-bold width-100" type="submit">
                  {{connectBtn}}
                </button>
              </div>
            </div>
            <div *ngIf="loginuser && !hideyes" class="card mb-1">
              <div class="card-body">
                <div class="row">
                  <h6 class="colorBlack line-height-30 ml-3 mt-1">
                    <strong>Is your package picked by carrier?</strong>
                  </h6>
                  <div class="col-6 m-t-20">
                    <button (click)="PostDealProcessYes()" class="btn btn-primary font-bold width-100" type="submit">
                      Yes
                    </button>
                  </div>
                  <div class="col-6 m-t-20">
                    <button (click)="
                        PostDealProcessNo()" class="btn btn-primary font-bold width-100" type="submit">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="loginuser && cancelRequested" class="card mb-1">
              <div class="card-body">
                <div class="row">
                  <h6 class="colorBlack line-height-30 ml-3 mt-1">
                    <strong>Do you want to cancel this deal?</strong>
                  </h6>
                  <div class="col-6 m-t-20">
                    <button (click)="openConfirmationCancelModal(confirmCancel)"
                      class="btn btn-primary font-bold width-100" type="submit">
                      Yes
                    </button>
                  </div>
                  <div class="col-6 m-t-20">
                    <button class="btn btn-primary font-bold width-100" (click)="CancelRequestAccept('0')"
                      type="submit">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="loginuser && showCancelBtn" class="row">
              <div class="col-12 m-t-20">
                <button id="button2" [disabled]="disableCancelBtn" style="background: #e32025; border-color: cf1d25"
                  (click)="openCancelModal(verifyCancel)" class="btn btn-primary font-bold width-100" type="submit">
                  Cancel deal?
                </button>
              </div>
            </div>
            <p *ngIf="pickUpAlert" class="alert-text">Wait for pickup confirmation.</p>
            <p *ngIf="deliveryAlert" class="alert-text">Wait for delivery confirmation.</p>
            <div *ngIf="loginuser && !hidestatus" class="row">
              <div class="col-12 m-t-20">
                <button [disabled]="disbaledstatusbtn" style="background: #e32025; border-color: #e32025" id="button1"
                  (click)="PostDealProcess(verificationmodal)" class="btn btn-primary font-bold width-100"
                  type="submit">
                  {{ btnStatus }}

                </button>
              </div>
            </div>

            <div *ngIf="loginuser && !hidedileverybtn" class="row">
              <div class="col-12 m-t-20">
                <button style="background: #e32025; border-color: #e32025" id="button1"
                  (click)="PostDealProcessDel(verificationmodal)" class="btn btn-primary font-bold width-100"
                  type="submit">
                  Confirm Delivery
                </button>
              </div>
            </div>

            <div class="row" *ngIf="loginuser &&  !hidefeedback">
              <div class="col-12 m-t-20">
                <button (click)="OpenFeedback(feedback)" class="btn btn-primary font-bold width-100" type="submit">
                  Feedback
                </button>
              </div>
            </div>
            <div *ngIf="!hidetrack" class="col-md-12 p-0">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h6 class="sender-inner-ad-desc">Track your Order</h6>
                    </div>
                    <div class="col-12">
                      <ul class="list-ic vertical">
                        <li>
                          <span [ngClass]="
                              hidewaitingtoaccept ? 'active' : 'deactive'
                            ">&nbsp;</span>
                          <a *ngIf="adData?.user_id == userId" class="sender-inner-ad-desc">Waiting for you to accept a
                            sender.</a>
                          <a *ngIf="adData?.user_id != userId" class="sender-inner-ad-desc">Your request is
                            accepted.</a>
                        </li>
                        <li>
                          <span [ngClass]="hidepickedupli ? 'active' : 'deactive'">&nbsp;</span>
                          <a *ngIf="adData?.user_id == userId" class="sender-inner-ad-desc">You picked up the package
                            from sender.</a>
                          <a *ngIf="adData?.user_id != userId" class="sender-inner-ad-desc">Your package is picked
                            up.</a>
                        </li>
                        <li>
                          <span [ngClass]="hidereachedli ? 'active' : 'deactive'">&nbsp;</span>
                          <a *ngIf="adData?.user_id == userId" class="sender-inner-ad-desc">You reached the
                            destination.</a>
                          <a *ngIf="adData?.user_id != userId" class="sender-inner-ad-desc">Carrier reached the
                            destination.</a>
                        </li>
                        <li>
                          <span [ngClass]="hideverifypinli ? 'active' : 'deactive'">&nbsp;</span>
                          <a class="sender-inner-ad-desc">Pin verify successfully.</a>
                        </li>
                        <li>
                          <span [ngClass]="hidedelieveredli ? 'active' : 'deactive'">&nbsp;</span>
                          <a class="sender-inner-ad-desc">Package handed off to Point of Contact.</a>
                        </li>
                        <li>
                          <span [ngClass]="hidedelieveredli ? 'active' : 'deactive'">&nbsp;</span>
                          <a class="sender-inner-ad-desc">Deal completed.</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Style>

      ::ng-deep .modal-dialog .modal-content{
        width:500px !important;

      }
      </Style>

    <div [formGroup]="VerificationForem">
      <ng-template #verificationmodal let-modal>
        <div class="modal-header">
          <h4 class="modal-title w-100 font-21 font-500 mt-4" id="modal-basic-title">Verification PIN</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input id="number" formControlName="verification" autocorrect="off" autocapitalize="off" spellcheck="off"
            type="text" class="form-control" placeholder="Enter a Verify PIN" />
        </div>
        <div class="modal-footer" *ngIf="loginuser">
          <button style="
              background: #e32025;
              border-color: #e32025;
              height: 53px;
              border-radius: 9px;
            " type="button" class="btn btn-primary font-bold width-100 m-t-10 m-b-10" (click)="
              postVerify()">
            Verify
          </button>
        </div>
      </ng-template>

      <ng-template #verifyCancel let-modal>
        <div class="w-100 h-300 w-500">
          <div class="modal-header">
            <h4 class="modal-title w-100 font-21 font-500" id="modal-basic-title">
              Attention Requiraed
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body text-center verify-card-mob-responsive" style="padding-top: 0px !important">
            <p style="font-size: 16px !important" class="color-figma-photo text-center mb-2 color-figma-photo-mob">
              Are you sure you want to cancel the deal with {{ cancelDealWith }}?
            </p>
            <div *ngIf="loginuser">
            <button style="height: 53px; border-radius: 9px" type="button"
              class="btn btn-primary font-bold w-100 m-t-10 m-b-10" (click)="
                SendCancelRequest(cancelRequestSent);
                modal.dismiss('Cross click')
              ">
              Yes, cancel the deal
            </button>
            <button style="height: 53px; border-radius: 9px" type="button"
              class="btn btn-primary font-bold w-100 m-t-10 m-b-10" (click)="modal.dismiss('Cross click')">
              No, don't cancel
            </button>
          </div>
        </div>
        </div>
      </ng-template>

      <ng-template #confirmCancel let-modal>
        <div class="w-100 h-300 w-500">
          <div class="modal-header">
            <h4 class="modal-title w-100 font-21 font-500" id="modal-basic-title">
              Attention Required
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body text-center verify-card-mob-responsive" style="padding-top: 0px !important">
            <p style="font-size: 16px !important" class="
                color-figma-photo
                text-center
                mt-2
                mb-2
                color-figma-photo-mob
              ">
              Are you sure you want to cancel the deal with {{ cancelDealWith }}?
            </p>
            <div *ngIf="loginuser">
            <button style="height: 53px; border-radius: 9px" type="button"
              class="btn btn-primary font-bold width-100 m-t-10 m-b-10"
              (click)="CancelRequestAccept('1'); modal.dismiss('Cross click')">
              Yes, cancel the deal
            </button>
            <button style="height: 53px; border-radius: 9px" type="button"
              class="btn btn-primary font-bold width-100 m-t-10 m-b-10"
              (click)="CancelRequestAccept('0'); modal.dismiss('Cross click')">
              No, don't cancel
            </button>
          </div>
        </div>
        </div>
      </ng-template>

      <ng-template #cancelRequestSent let-modal>
        <div class="w-100 h-auto w-500">
          <div class="modal-header">
            <h4 class="modal-title font-21 font-500 w-100" id="modal-basic-title">
              Cancellation request sent
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center verify-card-mob-responsive">
            <img src="../../../assets/verificationSuccessful.png" class="w-50" />
            <p style="font-size: 16px !important" class="color-figma-photo text-center mt-2 mb-2 color-figma-photo-mob">
              Your request to cancel the deal has been sent successfully. The deal will be cancelled once
              {{ cancelDealWith }} has accepted the request.
            </p>
          </div>
          <div class="modal-footer pl-3 pr-3 pb-3" *ngIf="loginuser">
            <button style="height: 53px; border-radius: 9px" type="button"
              class="btn btn-primary font-bold width-100 m-t-10 m-b-10" (click)="modal.close('Save click');">
              Ok
            </button>
          </div>
        </div>
      </ng-template>

      <div [formGroup]="FeedbackForm">
        <ng-template #feedback let-modal>
          <div id="feedbackPopup" class="modal fade show" role="dialog" aria-modal="true"
            style="display: block; padding-left: 17px">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="w-100 h-400 w-500">
                  <div class="modal-header mt-2">
                    <h3 class="
                        modal-title
                        text-center
                        w-100
                        font-21
                        pt-0
                        pb-3
                        headd
                      ">
                      Feedback
                    </h3>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div class="modal-body text-center pt-0 pb-2">


                    <div class="row">

                      <div class="col-12 m-b-10 text-center">
                        <div class="row"></div>
                        <div class="row mt-4 mb-5">
                          <div class="col" *ngFor="let value of emojis">
                            <img (click)="RatingClick()" [src]="value.img" class="width-100" (click)="SelectedEmo(value)"
                              [ngStyle]="{'opacity': value.opacity}" />
                          </div>
                      </div>

                        <div class="row m-b-10 m-t-10">
                          <div class="col-12">
                            <textarea formControlName="review" type="text" class="form-control text-left" #review
                              placeholder="Type Something.."></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="loginuser">
                    <button *ngIf="ratingSubmit" (click)="PostFeedback()" data-dismiss="modal" data-toggle="modal"
                      data-target="#deliveryPriority" class="btn btn-primary font-bold width-100 m-t-10 m-b-10"
                      type="submit">
                      Submit
                    </button>

                    <button *ngIf="!ratingSubmit" (click)="PostFeedback()" data-dismiss="modal" data-toggle="modal"
                    data-target="#deliveryPriority" class="btn btn-primary font-bold width-100 m-t-10 m-b-10"
                    type="submit" disabled>
                    Submit
                  </button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
