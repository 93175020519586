<div class="container-fluid p-t-b-40">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto text-center padding-zero">
                <img class="max-width-200" src="https://cdn.pixabay.com/photo/2017/02/12/21/29/false-2061132_960_720.png">
                <h3 class="colorBlack font-700 font-50"> Whooops!</h3>
                <p class="colorB6 font-16 m-t-20">
                    <!-- We couldn't find the page you were looking for. Maybe our FAQ or Community can help? -->
                    Opps! It seems that you are looking for the page that has expired or maybe removed from the server.
                    <br>
                    <br>
                </p>
            </div>
        </div>
    </div>
</div>
