<div class="container-fluid mt-4" #terms>
  <div class="p-t-b-40">
    <div class="col-md-12 text-center mt-5 mb-2">
      <h3 class="colorRed-2 font-37 font-600">Terms and Conditions</h3>
    </div>
    <div class="row justify-content-center align-items-center">

      <p [innerHTML]="dataTerms" class="px-0 pt-5 col-12 col-md-8 p-b-10 d-flex justify-content-center align-items-center flex-column text-justify mob-v-p-37"></p>
    </div>
  </div>
</div>
