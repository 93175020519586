<div class="bgcolor-1">
  <div class="container">
    <div class="row">

      <div class="col-md-9 m-t-b-10 padding-zero-mobile" >
        <div class="card m-b-20 min-vh-75">
          <div class="card-body">
            <div class="row backgroundWhite" >
              <div class=" col-md-6 col-12">
                <h4>Trip Info

                </h4>
              </div>
              <div class="col-md-6 col-12 text-left">
                <a class="buttonTripInfoWhite buttonWhiteRound pull-right"><i class="fa fa-heart colorRed"></i></a>
							<a class="buttonTripInfoWhite buttonWhiteRound pull-right"><i class="fa fa-share"></i></a>
              </div>
              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Type</h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Clothing</h6>

              </div>

              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Weight</h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">4kg</h6>

              </div>

              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Destination</h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Jumerah beach hotel, Jumeira Road, Umm<br>Suqieum 3 - Dubai - UAE</h6>

              </div>

              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Willing To</h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">45 AED</h6>

              </div>
              <div class=" col-md-6 col-12">
                <h4 class="m-t-20">Other Info</h4>
              </div>
              <div class="col-md-6 col-12 text-left">
              </div>
              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Capacitites</h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Clothing</h6>

              </div>

              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Categories</h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">4kg</h6>

              </div>

              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Travelling by</h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">

                <a class="btn btn-info btn-tag" >Clothing</a>
                <a class="btn btn-info btn-tag" >Entertainment</a>
                <a class="btn btn-info btn-tag" >Electronics</a>

              </div>

              <div class="col-4 col-md-3 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">Cost </h6>

              </div>
              <div class="col-8 col-md-9 text-left m-t-10">
                <h6 class="colorBlack line-height-30 font-16">45 AED</h6>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 m-t-b-10 padding-zero-mobile" >
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-8">
                <h6 class="colorBlack line-height-30"><strong>Marium Mustafa</strong></h6>
                <h6 class="colorBlack line-height-30 font-14 font-400">Sender</h6>
                <h6 class="colorBlack line-height-30 font-14 font-400">Joined since <strong>March 2019</strong></h6>
                <h6 class="colorBlack line-height-30 font-14 font-400">23 packages sent</h6>
              </div>
              <div class="col-4">
                <img src="images/mariamMustafa.png" class="width-100">
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 m-t-20 ">
                <button data-toggle="modal" data-target="#verificationStep1" class="btn btn-primary font-bold width-100" type="submit">Connect</button>
              </div>
        </div>
      </div>
    </div>
  </div>
</div>
