<div class="desktop-margin-top bgcolor-1">
  <div class="container">
    <div class="row">
      <div class="col-md-12 m-t-b-20">
        <div class="row">
          <div *ngIf="!showChatMobile || showChatMobile == undefined" class="
              col-lg-3 col-md-3 col-sm-12 col-12
              text-right
              mob-v-card-top-margin
            ">
            <div class="card overflow-look-after border-rad-10">
              <div class="card-body pt-2 margin-10 height-550">
                <div class="row">
                  <div class="col-md-12 padding-zero text-left">
                    <div class="form-group has-search">
                      <svg class="form-control-feedback" width="32" height="32" viewBox="0 0 32 32" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.3333 29.3327L20.6666 20.666" stroke="#CCD4DB" stroke-width="2" />
                        <circle cx="13.3333" cy="13.3327" r="10.6667" stroke="#CCD4DB" stroke-width="2" />
                      </svg> <input type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                        class="form-control" placeholder="Search" />
                    </div>
                  </div>

                  <div class="col-md-12 padding-zero text-left">
                    <img *ngIf="googleAdsImageChatTop != undefined" class="w-100 mb-3" [src]="
                        'https://pacsend.tech/public/uploads/slots/' +
                          googleAdsImageChatTop | byPassSecurityUrl
                      " />
                    <div *ngIf="googleAdsScript != undefined" [innerHTML]="googleAdsScript | byPassSecurityScript">
                    </div>
                  </div>

                  <div class="col-md-12 px-0">
                    <div class="row mx-0">
                      <div (click)="clickSenderHow()" class="col-6 col-xs-6 col-sm-6 cursor" [ngClass]="
                          clickSender
                            ? 'mob-v-maindiv-btn'
                            : 'mob-v-maindiv-btn-2nd'
                        ">
                        <p class="d-flex align-items-center justify-content-center m-0 py-3 px-0 w-100 h-100" [ngClass]="
                            clickSender
                              ? 'mob-v-maindiv-btn-p'
                              : 'mob-v-maindiv-btn-2nd-p'
                          ">
                          Sender
                        </p>
                      </div>
                      <div (click)="clickCarrierHow()" class="col-6 col-xs-6 col-sm-6 cursor" [ngClass]="
                          clickCarrier
                            ? 'mob-v-maindiv-btn'
                            : 'mob-v-maindiv-btn-2nd'
                        ">
                        <p class="d-flex align-items-center justify-content-center m-0 py-3 px-0 w-100 h-100" [ngClass]="
                            clickCarrier
                              ? 'mob-v-maindiv-btn-p'
                              : 'mob-v-maindiv-btn-2nd-p'
                          ">
                          Carriers
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- /////////////////// SENDER //////////////////// -->
                <div class="row p-t-b-10 m-b-10" *ngIf="senderChats" [@zoomIn]="zoomIn">
                  <div *ngFor="
                      let item of Sender | filter: searchText;
                      let i = index
                    " class="col-md-12 m-t-10">
                    <div [ngClass]="
                        senderSelect[i] ? 'row row-chat-click' : 'row row-chat'
                      " (click)="ClickChat(item.chat_head_id); senderSelect1(i)">
                      <div class="
                          col-md-3 col-3
                          text-left
                          p-r-0 p-l-0
                          position-relative p-r-0 p-l-5">
                        <img [src]="item.custom_profile_photo? imageBaseUrl + item.custom_profile_photo: item['chat_user'].chat_image? imageBaseUrl + item['chat_user'].chat_image : '../../../assets/default.jpg'" class="image-avatar" />

                      </div>

                      <div class="col-md-7 col-7 text-left p-l-8">
                        <h6 [ngClass]="
                            senderSelect[i]
                              ? 'font-16 font-500 chat-card-heading line-height-18-imp color-white'
                              : 'font-16 font-500 chat-card-heading line-height-18-imp color-black-shade'
                          ">
                          {{ item["chat_user"].chat_name }}
                        </h6>
                        <h6 [ngClass]="
                            senderSelect[i]
                              ? 'font-14 font-400 chat-card-desc color-white'
                              : 'font-14 font-400 chat-card-desc color-figma-gray'
                          ">
                          {{
                          item.sender_ad_title &&
                          (item.sender_ad_title | slice: 0:7)
                          }}

                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /////////////////// CARRIER //////////////////// -->
                <div class="row p-t-b-10 m-b-10" *ngIf="carrierChats" [@zoomIn]="zoomIn">
                  <div *ngFor="
                      let item of Carrier | filter: searchText;
                      let i = index" class="col-md-12 m-t-10">
                    <div 
                      [ngClass]="carrierSelect[i] ? 'row row-chat-click' : 'row row-chat'" 
                      (click)="ClickChat(item.chat_head_id); carrierSelect1(i)"
                    >
                      <div class="col-md-3 col-3 text-left p-r-0 p-l-0 position-relative">
                        <img 
                        [src]="item.custom_profile_photo? imageBaseUrl + item.custom_profile_photo: item['chat_user'].chat_image? imageBaseUrl + item['chat_user'].chat_image : '../../../assets/default.jpg'" class="image-avatar" 
                        />

                      </div>
                      <div class="col-md-7 col-7 text-left p-l-8">
                        <h6 [ngClass]="
                            carrierSelect[i]? 'font-16 font-500 chat-card-heading line-height-18-imp color-white': 'font-16 font-500 chat-card-heading line-height-18-imp color-black-shade'">
                          {{ item["chat_user"].chat_name }}
                        </h6>
                        <h6 [ngClass]="
                            carrierSelect[i]
                              ? 'font-14 font-400 chat-card-desc color-white'
                              : 'font-14 font-400 chat-card-desc color-figma-gray'
                          ">
                          {{ item.carrier_ad_title }}

                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!checkChatShow || !chatShowDisable" class="col-lg-9 col-md-9 col-sm-12 col-12">

            <img src="https://pacsend.tech/public/uploads/users/chetEmpty.png" class="blank Chat image w-100" />
          </div>
          <div *ngIf="
              checkChatShow && (showChatMobile || showChatMobile == undefined) && (chatShowDisable)
            " class="
              col-lg-9 col-md-9 col-sm-12 col-12
              text-left
              mob-view-chat-none
            " [@zoomIn]="zoomIn">
            <div class=""></div>
            <div class="card">
              <div class="card-body p-t-b-10">
                <div class="row">
                  <div *ngIf="showChatMobile" class="">
                    <a (click)="showChatMobile = false"><i class="fas fa-angle-left cursor font-19 color-figma-gray mt-mob-19 mt-3"></i></a>
                  </div>

                  <div class="col-3 col-xs-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 position-relative">
                    <img [src]="requesterimage? imageBaseUrl + requesterimage: '../../../assets/default.jpg' " class="image-avatar" />
                  </div>
                  <div class="col-6 col-xs-6 col-sm-6 col-md-10 col-lg-10 col-xl-10 mt-1 p-l-5 top-5">
                    <h4 class="chat-username user-font">{{ requestername }}</h4>
                    <h6 class="chat-last-seen color-figma-gray line-height-30 font-16 font-500" *ngIf="isUserActive">
                      <i class="fas fa-circle font-12 color-green-get" ></i>
                    {{userStatus.trim() == 'online' ? userStatus : 'offline'}}
                    </h6>
                    <h6 class="chat-last-seen color-figma-gray line-height-30 font-16 font-500" *ngIf="!isUserActive">
                      <i class="fas fa-circle font-12" ></i>
                    {{userStatus.trim() == 'online' ? userStatus : 'offline'}}
                    </h6>
                  </div>
                  <div *ngIf="hideaward" class="
                      col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6
                      text-right
                      m-t-10
                      mob-view-key
                    ">
                    <a (click)="PostAward(pointOfContact)" *ngIf="!hideshield"><b class="color-figma-gray">Assign job&nbsp;</b>
                      <i class="
                          fas
                          fa-shield-alt
                          cursor
                          font-19
                          color-figma-gray
                        "></i></a>
                    <a (click)="PostVerificationPIN(pinmodal)" *ngIf="!hidekey"><b class="color-figma-gray">PIN Code
                        &nbsp;</b>
                      <i class="fas fa-key cursor font-19 color-figma-gray"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div #scrollMe class="overflowscroll">
              <div class="row">
                <div class="col-12 col-md-6 text-left mt-3">
                  <div class="CBox rounded mb-3" (click)="SeeAdDetail()">
                    <div *ngIf="Chat?.ad_type == 1" class="cardimg h-180 rounded-top border-bottom"><img [src]="adBaseImgUrl +  Chat?.ad_image" alt=""></div>
                    <div class="CBoxBody d-flex flex-column p-4">
                      <div class="TitleBar text-left pt-4 pb-4 pl-3">
                        <h5 _ngcontent-ebm-c11="">
                          {{ Chat?.ad_title?Chat?.ad_title:'____' }}
                        </h5>
                      </div>
                      <div class="DetailBox pl-3 pl-lg-5 cursor cursor">
                        <div class="row">
                          <div class="col-md-6 col-lg-7 pl-4 pl-lg-0 LocationBox">
                            <div class="InfoBox PickupBox d-flex flex-column text-left">
                              <i class="circle"></i>
                              <label>Pick Up</label>
                              <span>
                                {{
                                  Chat?.ad_from_location
                                  ? (Chat?.ad_from_location)
                                  : ("N/A" | uppercase)
                                }}
                              </span>
                            </div>
                            <div class="InfoBox d-flex flex-column text-left">
                              <i class="circle"></i>
                              <label>Drop Off</label>
                              <span>                                
                                {{
                                  Chat?.ad_to_location
                                  ? (Chat?.ad_to_location)
                                  : "N/A"
                                }}
                                <!-- - {{ item.to_time }} -->
                              </span>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-5 pt-4 pt-md-0 pr-0">
                            <div class="row">
                              <div class="InfoBox d-flex flex-column text-left col-6 col-md-12">
                                <label> Day of arrival</label>
                                <span>
                                  {{ Chat?.custom_date_to | formatdates }}
                                  <!-- {{ Chat?.custom_date_to | date:'dd-MMM-yy'}} -->
                                </span>
                              </div>
                              <div class="InfoBox d-flex flex-column text-left col-6 col-md-12">
                                <label>Capacity</label>
                                <span>{{ Chat?.ad_weight }} Kgs</span>
                              </div>
                              <div class="InfoBox d-flex flex-column text-left col-6 col-md-12">
                                <label>Size</label>
                                <span>
                                  <img src="https://pacsend.tech/public/uploads/travelingBy/{{
                                    Chat.traveling_by_image
                                  }}" height="20" width="15" class="d-none" />                                
                                  {{ Chat.traveling_by_fullname }}
                                </span>
                              </div>
                              <div
                                class="InfoBox d-flex flex-column text-left col-6 col-md-12 d-block d-md-none">
                                <label>Willing to pay</label>
                                <span>{{ Chat?.ad_payement }} {{ Chat?.currency }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="TagBar d-flex flex-row flex-wrap py-4 pl-lg-4 pl-3 pr-3">
                        <a *ngFor="let tags of Chat['categories']"
                          class="badge badge-pill mr-1 mb-1 font-12 ng-star-inserted text-capitalize">
                          <img [src]="tagImgUrl + tags?.category_image" width="16px" />
                          {{ tags.category_name }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <!--CBOX x here-->
                </div>
              </div>
              <div class="row" #scrollBot [scrollTop]="scrollBot.scrollHeight">
                <div class="col-12">

                  <ngx-ui-loader fgsPosition="center-center" overlayColor="rgba(40,40,40,0)"
                    [hasProgressBar]="l.hasProgressBar" [logoSize]="l.logoSize" [fgsType]="l.spinnerType"
                    [loaderId]="'my-ads'">
                  </ngx-ui-loader>
                  <!-- open image code here  junaid-->
                  <ng-template #chatpicopend let-modal>
                    <div class="container">
                      <div class="open-img-box">
                        <div class="row mr-0 ml-0 mt-2">
                          <div class="col-md-6 col-sm-6 col-6 bg-color-white pl-0">
                          </div>
                        </div>
                        <img class="chat-img-open" style="width: 600px; height: 350px;" [src]="imageOPen"
                          alt="Chat open image" />
                      </div>
                    </div>
                  </ng-template>

                  <div>
                    <div *ngFor="let item of Message | sortData: 'dateTime'" class="row chatbox">
                      <div class="col-8"></div>
                      <div class="col-12 col-md-4 text-left">
                        <div *ngIf="item.message_type == 0" [ngClass]="
                            userid == item.sender_id
                              ? 'messageClass1'
                              : 'messageClass2'
                          " class="card"
                          [ngStyle]=" {right: (userid != item.sender_id && !isMobileScreen)  ? '600px' : '0'}" 
                          >
                          <div class="card-body p-10">
                            <div class="row">
                              <div class="col-12">
                                <p id="messagetype" class="font-16">
                                  {{ item.message || "Hi ! Am intrested in your ad" }}
                                </p>
                              </div>
                              <div class="col-12 text-right font-12">
                                <!-- {{ item.dateTime | date: "shortTime" }} -->
                                {{ item.chatDateTime }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- chat picture  -->
                        <div *ngIf="item.message_type == 1" class="card" [ngClass]="userid == item.sender_id? 'messageClass1': 'messageClass2'"
                        [ngStyle]=" {right: (userid != item.sender_id && !isMobileScreen)  ? '600px' : '0'}" 
                          (click)="chatPicOpen(chatpicopend,item.attachment)">
                          <div class="card-body pt-2 pb-2">
                            <div class="row">
                              <div class="col-12 position-relative pl-0 pr-0">
                                <img [src]="
                                    'https://pacsend.tech/public/uploads/chat/' +
                                    item.attachment
                                  " class="
                                    img-resposnive
                                    w-100
                                    border-rad-10
                                    cursor
                                  " />
                                <p class="text-light pos-abs-r-b font-12">
                                  {{ item.chatDateTime }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="item.message_type == 3" class="card" [ngClass]="userid == item.sender_id? 'messageClass1': 'messageClass2'"
                        [ngStyle]=" {right: (userid != item.sender_id && !isMobileScreen)  ? '600px' : '0'}" >
                          <div class="card-body pb-1">
                            <div class="row">
                              <div class="col-md-12">
                                <img src="assets/ICONS-04.png" alt="photo" />
                                <span class="font-13">{{
                                  item.attachment | slice: 0:30
                                  }}</span>
                              </div>
                              <div class="col-md-12 text-right">
                                <a [href]="
                                    'https://pacsend.tech/public/uploads/chat/' +
                                    item.attachment
                                  " target="_blank">
                                  <i class="fas fa-arrow-circle-down font-25"></i>
                                </a>
                              </div>
                              <div class="col-8"></div>
                              <div class="col-4 text-right font-12">
                                <p class="font-12 text-right mb-0">
                                  {{ item.chatDateTime }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="item.message_type == 2" class="card" [ngClass]="userid == item.sender_id? 'messageClass1': 'messageClass2'"
                        [ngStyle]=" {right: (userid != item.sender_id && !isMobileScreen)  ? '600px' : '0'}" >
                          <div class="card-body pb-1">
                            <div class="row">
                              <div class="col-md-12">
                                <a *ngIf="item.extension == 'pdf'" [href]="
                                    'https://pacsend.tech/public/uploads/chat/' +
                                    item.attachment
                                  " target="_blank">
                                  <img src="assets/ICONS-01.png" alt="photo" />
                                  <span class="font-13">{{
                                    item.attachment | slice: 0:27
                                    }}</span>
                                </a>

                                <a *ngIf="item.extension == 'zip' ||
                                item.extension == 'rar'" [href]="
                                'https://pacsend.tech/public/uploads/chat/' +
                                item.attachment
                              " target="_blank">
                                  <img src="assets/ICONS-08.png" alt="photo" />
                                  <span class="font-13">{{
                                    item.attachment | slice: 0:27
                                    }}</span>
                                </a>

                                <a *ngIf="
                                    item.extension == 'doc' ||
                                    item.extension == 'docx'
                                  " [href]="
                                    'https://pacsend.tech/public/uploads/chat/' +
                                    item.attachment
                                  " target="_blank">
                                  <img src="assets/ICONS-03.png" alt="photo" />
                                  <span class="font-13">{{item.attachment | slice: 0:27 }}</span>
                                </a>
                                <a *ngIf="
                                    item.extension == 'xls' ||
                                    item.extension == 'xlsx'
                                  " [href]="
                                    'https://pacsend.tech/public/uploads/chat/' +
                                    item.attachment
                                  " target="_blank">
                                  <img src="assets/ICONS-02.png" alt="photo" />
    
                                  <span class="font-13">{{
                                    item.attachment | slice: 0:27
                                    }}</span>
                                </a>
                                <a *ngIf="
                                    item.extension == 'ppt' ||
                                    item.extension == 'pptx'
                                  " [href]="
                                    'https://pacsend.tech/public/uploads/chat/' +
                                    item.attachment
                                  " target="_blank">
                                  <img src="assets/ICONS-07.png" alt="photo" />
                                  <span class="font-13">{{
                                    item.attachment | slice: 0:27
                                    }}</span>
                                </a>
                                <a *ngIf="item.extension == 'txt'" [href]="
                                    'https://pacsend.tech/public/uploads/chat/' +
                                    item.attachment
                                  " target="_blank">
                                  <img src="assets/ICONS-06.png" alt="photo" />
                                  <span class="font-13">{{
                                    item.attachment | slice: 0:27
                                    }}</span>
                                </a>
                              </div>
                              <div class="col-md-12 text-right">
                                <i class="fas fa-arrow-circle-down font-25"></i>
                              </div>
                              <div class="col-8"></div>
                              <div class="col-4 text-right font-12">
                                <p class="font-12 text-right mb-0">
                                  {{ item.chatDateTime }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="item.message_type == 4" class="card" [ngClass]="userid == item.sender_id? 'messageClass1': 'messageClass2'"
                        [ngStyle]=" {right: (userid != item.sender_id && !isMobileScreen)  ? '600px' : '0'}" >
                          <div class="card-body pb-1">
                            <div class="row">
                              <div class="col-md-12 position-relative">
                                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="16">
                                </agm-map>
                                <p class="font-12 text-right mb-0">
                                  {{ item.chatDateTime }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div [hidden]="hidebutton" class="row">
                <div class="col-md-3 col-lg-3 col-xl-3"></div>
                <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <h5 class="font-600 color-figma-gray font-13">
                    Please watch awarness video before carrying or sending any
                    package
                  </h5>
                  <!-- PostSenderConnectFirebase('Connection request accepted by') -->
                  <button type="button"
                   class="btn btn-primary w-100 pad-top-13" 
                   (click)="PostAccept(); PostSenderConnectFirebase('Your Connection Request for')">
                    Accept
                  </button> 
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3"></div>

              </div>
              <div [hidden]="hidenomsg" class="row">
                <div class="col-md-4 text-left font-16 p-t-10 col-6">
                  <span class="display-block line-height-40">
                    <i class="fa fa-dot-circle-o font-bold m-l-360 font-33" aria-hidden="true"></i>
                    You have no request....
                  </span>
                </div>
              </div>
            </div>
            <div [formGroup]="ChatForm" class="card text-feild-mob clearfix" [hidden]="hidetext">
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-8 col-md-10">
                    <input #message formControlName="message" (keyup.enter)="onEnter()" type="text"
                    class="form-control border-none"  placeholder="Enter message.."/>
                  </div>
                  <div class="col text-right p-l-0 position-relative flex-items">
                    <div class="card-atatchment" *ngIf="hideAttachmentModal"
                     data-aos="fade-up" data-aos-easing="linear"
                      data-aos-duration="600" >
                      <div class="row mob-responsive-view" >
                        <div class="
                            col-lg-6 col-md-6 col-sm-6 col-6
                            text-center
                            p-1
                            bx
                          cursor" (click)="ImageClick(attahcment, 'image')">
                          <img src="../../../assets/ICONS-05.png" (click)="ImageClick(attahcment, 'image')"
                            class="w-30 img-responsive image-cursor" />
                          <p class="attachment-text mt-1">Photo</p>
                        </div>
                        <div class="
                            col-lg-6 col-md-6 col-sm-6 col-6
                            text-center
                            p-1
                            bx
                            cursor" (click)="ImageClick(attahcment, 'docs')">
                          <img src="../../../assets/google-docs.png" (click)="ImageClick(attahcment, 'docs')"
                            class="w-30 img-responsive" />
                          <p class="attachment-text mt-3">Documents</p>
                        </div>
                        <div class="
                            col-lg-6 col-md-6 col-sm-6 col-6
                            text-center
                            p-1
                            bx
                            cursor" (click)="ImageClick(attahcment, 'video')">
                          <img src="../../../assets/ICONS-04.png" (click)="ImageClick(attahcment, 'video')"
                            class="w-30 img-responsive" />
                          <p class="attachment-text mt-0">Video</p>
                        </div>
                        <div class="
                            col-lg-6 col-md-6 col-sm-6 col-6
                            text-center
                            p-1
                            bx
                            cursor" (click)="ImageClick(attahcment, 'location')">
                          <img src="../../../assets/pin.png" (click)="ImageClick(attahcment, 'location')"
                            class="w-30 img-responsive" />
                          <p class="attachment-text">Location</p>
                        </div>
                      </div>
                    </div>
                    <span (click)="Openattahcmentmodal(attahcment)">
                      <a *ngIf="hideAttachmentModal"><i class="
                            fa fa-paperclip
                            color-red-pin
                            font-25
                            mr-1
                            mt-1
                            pin-hover"></i></a>
                      <a *ngIf="!hideAttachmentModal"><i class="
                            fa fa-paperclip
                            color-figma-gray
                            font-25
                            mr-1
                            mt-1
                            pin-hover"></i></a>
                    </span>
                    <div class="display-inline border-red rounded-btn cursor">
                      <span (click)="GetSendMessaged(message.value)"><i class="fa fa-paper-plane font-19 colorRed"
                          aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>

                  <ng-template #attahcment let-modal>
                    <div class="w-100 w-500">
                      <div class="modal-header">
                        <h4 *ngIf="fileAttachmentType != 'location'" class="modal-title font-21 font-700 w-100"
                          id="modal-basic-title">
                          Attachment

                        </h4>
                        <h4 *ngIf="fileAttachmentType == 'location'" class="modal-title font-21 font-700 w-100"
                          id="modal-basic-title">

                          Location will be sent instantly without confirmation
                        </h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div *ngIf="fileAttachmentType != 'location'" class="modal-body pt-0 pb-0">
                        <div class="form-group">
                          <label for="file">File</label>
                          <input *ngIf="fileAttachmentType == 'docs'" type="file" name="file"
                            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf" (change)="handleFileInput($event, 2)"
                            id="formFile" />
                          <input *ngIf="fileAttachmentType == 'image'" type="file" name="file"
                            accept="image/x-png,image/gif,image/jpeg" (change)="handleFileInput($event, 1)"
                            id="formFile" />
                          <input *ngIf="fileAttachmentType == 'video'" type="file" name="file"
                            accept="video/mp4,video/x-m4v,video/*-" (change)="handleFileInput($event, 3)"
                            id="formFile" />
                        </div>


                      </div>
                      <div *ngIf="fileAttachmentType == 'location'" class="modal-body pt-0 pb-0 mob-v-card-padding">
                        <div class="form-group has-search mb-0">
                          <span class="
                              fas
                              fa-map-marker-alt
                              form-control-feedback
                              font-12
                              carry-icon-mob-responsive
                            "></span>
                          <input ngx-google-places-autocomplete [options]="options"
                            [ngModelOptions]="{ standalone: true }" [(ngModel)]="addressGmap" name="searchmap12"
                            #placesRef="ngx-places" (onAddressChange)="findLocation($event)" #searchmap12
                            id="searchmap12" autocorrect="off" autocomplete="off" autocapitalize="off" spellcheck="off"
                            type="text" class="form-control form-custom-control m-b-20" placeholder="Enter location" />
                        </div>

                        <div id="map" [hidden]="true">
                          <agm-map></agm-map>
                        </div>
                        <div>
                          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="20">
                            <agm-marker [latitude]="lat" [longitude]="lng">
                            </agm-marker>
                          </agm-map>
                        </div>

                      </div>
                      <div class="modal-footer pt-2 pb-3">

                        <button *ngIf="fileAttachmentType != 'location'" type="button" class="h-53 border-rad-10
                            btn btn-primary
                            font-bold
                            width-100
                            m-t-10 m-b-10
                          " (click)="modal.close('Save click'); okSend()">
                          OK
                        </button>

                      </div>
                    </div>
                  </ng-template>

                  <ng-template #pinmodal let-modal>
                    <div class="w-100 h-400 w-500">
                      <div class="modal-header verification-pin-text">
                        <h4 class="modal-title w-100 font-25 font-500 headd" id="modal-basic-title">
                          Verification PIN
                        </h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <p class="
                            font-weight-bold
                            text-center
                            mt-3
                            mb-4
                            font-600 color-figma-gray
                          ">
                          Compare this PIN with the carrier.
                        </p>
                        <h1 class="chat-username custom-font- text-center">
                          {{ pin }}
                        </h1>
                      </div>
                      <div class="modal-footer pb-0-desktop mt-4">
                        <button type="button" class="btn btn-primary font-bold width-100 m-t-10 h-53 border-rad-10"
                          (click)="modal.close('Save click')">
                          Okay
                        </button>

                      </div>
                    </div>
                  </ng-template>

                  <ng-template #pointOfContact let-modal>
                    <div class="w-100 h-400 w-500">
                      <div class="modal-header">
                        <h4 class="modal-title w-100 font-21 font-600" id="modal-basic-title">
                          Point Of Contact
                        </h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body pt-0 p-l-r-15">
                        <p>
                          Enter details of the receiver to whom traveller will
                          submit the package upon reaching the destination.
                        </p>

                        <div class="form-group m-t-20">
                          <input #name class="form-control" name="receiverName" type="text"
                            placeholder="Receiver Name" />
                        </div>
                        <div class="form-group">
                          <input #number class="form-control" name="MobileNumber" type="number"
                            placeholder="Mobile Number" />
                        </div>
                      </div>
                      <div class="modal-footer p-l-r-15-b-5">
                        <button type="button" class="h-53 border-rad-10
                            btn btn-primary
                            font-bold
                            width-100
                            m-t-10 m-b-10" (click)="
                            modal.close('Save click');
                            PostPointofContact(number.value, name.value)">Next</button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>