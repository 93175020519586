<section id="wrapper">
  <div class="login-register bgcolor-1 overflow-x-hidden p-7-0">
    <div class='row'>
      <div class="col-md-4"></div>
      <div class="col-12 col-md-4 text-center">
        <img class="img-responsive m-b-18 max-width-30p"
          [src]="'https://pacsend.tech/public/uploads/setting/PacsendFooter.png'">
      </div>
      <div class="col-md-4"></div>
    </div>

    <div class="login-box card">

      <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h3 class="signUp-heading-style login-text-responsive mb-4">Change Password</h3>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input #currentpassword type="password" placeholder="Enter Old Password" class="form-custom-control">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input #newpassword type="password" placeholder="Enter New Password" class="form-custom-control">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input #confirmpassword type="password" placeholder="Enter Confirm Password" class="form-custom-control">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <button (click)="ChangePassword(currentpassword.value ,newpassword.value,confirmpassword.value )" class="btn btn-primary w-100 btn-responsive mt-3 font-600 h-55p">Submit</button>
              </div>
            </div>

          </div>




      </div>
    </div>

  </div>
</section>


