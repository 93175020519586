import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tripinfo',
  templateUrl: './tripinfo.component.html',
  styleUrls: ['./tripinfo.component.css']
})
export class TripinfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
