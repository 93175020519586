<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta name="description" content="">
  <meta name="author" content="">
  <title>Pacsend?</title>

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" async>

</head>

<body class="skin-default card-no-border">
  <section id="wrapper">
    <div class="login-register login-register-bg-color-1">
      <div class='row'>
        <div class="col-md-4"></div>
        <div class="col-12 col-md-4 text-center">
          <img class="img-responsive max-width-30p m-b-18" [src]="'../../../assets/logo resize-02.webp'">
           </div>
        <div class="col-md-4"></div>
      </div>
      <div class="login-box card">
        <div class="card-body">
          <form class="form-horizontal form-material" [formGroup]="verificationForm">
            <div class="row">
              <div class="col-12">
                <h3 class="signUp-heading-style">Verification</h3>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <h5 class="text-center m-t-20 font-15">A 6-digit OTP is sent to <strong>Your Email</strong>
                  </h5><br>

                </div>
              </div>
              <div class="col-12">
                <input class="form-custom-control" formControlName="verification_code" type="text"
                  placeholder="OTP-CODE">
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <a  class="text-center m-t-20 m-b-0 font-15 reset-otp-btn" (click)="resendOTP()">Resend OTP</a>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-12">
                <div class="form-group">
                  <button class="btn btn-primary m-t-20 width-100" (click)="forgetPasswordVerification() "
                    type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 text-center">
      </div>
    </div>
  </section>
</body>
</html>
