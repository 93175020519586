<style>
  ::ng-deep div .iti__country-list--dropup{
    width: 389px;
  }

  ::ng-deep div .iti__country-list{
    width: 389px;
  }
</style>
<div class="d-md-flex justify-content-center align-items-center bgcolor-1 w-100 h-100 text-center overflow-auto">
<section id="wrapper">
  <div class="login-register login-register-bg-color">
    <div class='row'>
      <div class="col-md-4"></div>
      <div class="col-12 col-md-4 text-center ">
        <a [routerLink]="['/']" routerLinkActive="router-link-active">
        <img class="w-75 mb-3"
          [src]="'https://pacsend.tech/public/uploads/setting/PacsendFooter.png'">
        </a>
      </div>
      <div class="col-md-4"></div>
    </div>

    <div class="login-box card">
      <div class="card-body border-radiuss">
        <form class="form-horizontal form-material" [formGroup]="signUpForm" (submit)="onSubmit()">
          <div class="row">
            <div class="col-12">
              <h3 class="signUp-heading-style mob-text-responsive mb-4">Create Account</h3>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <input class="form-custom-control" formControlName="first_name"  type="text" placeholder="First Name" >
              </div>
            </div>
            <div class="col-6">
              <div class="form-group ">
                <input class="form-custom-control" formControlName="last_name"  type="text" placeholder="Last Name">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group ">
                <input class="form-custom-control" formControlName="email"   type="email" placeholder="Email">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input class="form-custom-control" formControlName="password" [type]="inputType" placeholder="Password">
                <i class="bi bi-eye possword-eye" *ngIf="isPasswordVisible" (click)="viewPassord('hide')"></i>
                <i class="bi bi-eye-slash possword-eye" *ngIf="!isPasswordVisible" (click)="viewPassord('show')"></i>
              </div>
            </div>
            <div class="col-12 m-b-20">
              <div class="form-group" *ngIf="isCountry">
                <input type="text" ng2TelInput [ng2TelInputOptions]="{initialCountry: country}" maxlength="10"
                  class="form-custom-control number-field" formControlName="contact"  />
              </div>
              <div class="form-group" *ngIf="!isCountry">
                <input type="text" ng2TelInput [ng2TelInputOptions]="{initialCountry: country}" maxlength="10"
                  class="form-custom-control number-field"  formControlName="contact" />
              </div>
            </div>


            <div class="col-12">
              <ngx-ui-loader [bgsColor]="'#fff'" [bgsSize]="40" [bgsType]="'three-bounce'" [loaderId]="'saveButton'">
              </ngx-ui-loader>
                <button class="btn btn-primary width-100 h-90"  type="submit">Signup</button>
            </div>
          </div>
        </form>

        <form class="form-horizontal display-none" id="recoverform" action="index.html">
          <div class="form-group ">
            <div class="col-xs-12 ">
              <h3>Recover Password</h3>
              <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
            </div>
          </div>
          <div class="form-group ">
            <div class="col-xs-12">
              <input class="form-control" type="text" required="" placeholder="Email">
            </div>
          </div>
          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                type="submit">Reset</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-12 text-center bottom-text ">
      <p>Already got an account ? <span (click)="loginpage()" class="pointer"><b>Login</b></span></p>
    </div>
  </div>
</section>
</div>
